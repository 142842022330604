import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute, Router} from "@angular/router";
import {showAPIError} from "../../utils/api";
import {RFC3339} from "../../interfaces/datetime";
import {DateUtils} from "../../utils/date";

interface LinkDef {
  name: string;
  url: string;
}

@Component({
  selector: 'epl-week-overview-selector',
  templateUrl: './week-overview-selector.component.html',
  styleUrls: ['./week-overview-selector.component.scss']
})
export class WeekOverviewSelectorComponent implements OnInit {

  mode: 'week'|'month'|'year' = 'week';

  loading: boolean = true;
  minDate: RFC3339|null = null;
  maxDate: RFC3339|null = null;

  weeks:  LinkDef[] = [];
  months: LinkDef[] = [];
  years:  LinkDef[] = [];

  constructor(private apiservice: ApiService,
              private activeRoute: ActivatedRoute,
              private notification: NzNotificationService,
              private router: Router) { }


  async ngOnInit() {
    try {
      this.mode = this.activeRoute.snapshot.data['mode'] as ('week'|'month'|'year');

      const resp = await this.apiservice.getDateRange()

      this.minDate = resp.min;
      this.maxDate = resp.max;

      if (this.minDate === null || this.maxDate === null) {
        this.weeks = [];
        this.loading = false;
        return
      }

      {
        let weeks = [];

        let max = DateUtils.parseRFC3339(this.maxDate);
        let i = DateUtils.parseRFC3339(this.minDate);
        i = DateUtils.startOfWeek(i)

        while (i.getTime() <= max.getTime()) {
          const kw = DateUtils.getDateWeek(i);
          weeks.push({ 'name': i.getFullYear() + ' - KW ' + kw, 'url': `/overview/week/${i.getFullYear()}/${kw}` });
          i = new Date(i);
          i.setDate(i.getDate() + 7);
        }

        this.weeks = weeks.reverse();
      }

      {
        let months = [];

        let max = DateUtils.parseRFC3339(this.maxDate);
        let i = DateUtils.parseRFC3339(this.minDate);
        i = new Date(i.getFullYear(), i.getMonth(), 1);

        while (i.getTime() <= max.getTime()) {
          months.push({ 'name': DateUtils.getMonthStr(i), 'url': `/overview/month/${i.getFullYear()}/${i.getMonth()}` });
          i = new Date(i.getFullYear(), i.getMonth()+1, 1);
        }

        this.months = months;
      }

      {
        let years = [];

        let max = DateUtils.parseRFC3339(this.maxDate);
        let i = DateUtils.parseRFC3339(this.minDate);
        i = new Date(i.getFullYear(), 0, 1);

        while (i.getTime() <= max.getTime()) {
          years.push({ 'name': `${i.getFullYear()}`, 'url': `/overview/year/${i.getFullYear()}` });
          i = new Date(i.getFullYear()+1, 0, 1);
        }

        this.years = years;
      }


      this.loading = false;

    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
    }
  }

}
