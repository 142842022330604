import { Component, OnInit } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import {ApiService} from "../../services/api.service";
import {Table} from "../../interfaces/table";
import {Router} from "@angular/router";
import {Sheet} from "../../interfaces/sheet";
import {showAPIError} from "../../utils/api";
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: 'epl-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.scss']
})
export class TablesComponent implements OnInit{
  tables: Table[] = [];

  constructor(private apiservice: ApiService,
              private notification: NzNotificationService,
              private router: Router) {

  }

  ngOnInit(): void {
    this.getTables().then(() => {});
  }

  async getTables() {
    try {
      this.tables = (await this.apiservice.getTables()).tables;
    } catch (err) {
      showAPIError(this.notification, 'Tabellen konnten nicht geladen werden', err);
    }
  }

  async deleteTable(data: Table) {
    try {
      await this.apiservice.deleteTable(data.id);
      this.notification.success("Erfolg", "Tabelle wurde gelöscht");
      this.tables = this.tables.filter(p => p.id !== data.id);
    } catch (err) {
      showAPIError(this.notification, 'Tabellen konnten nicht geladen werden', err);
    }
  }

  async openLatestSheet(data: Table) {
    try {
      const sheets = (await this.apiservice.getTableHistory(data.id)).sheets;
      await this.router.navigate(['/edit-sheet', sheets[0].sheetId]);
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
    }
  }
}
