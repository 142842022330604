<epl-default-frame>

    <div class="fullpageloader" *ngIf="loading">
        <nz-spin></nz-spin>
    </div>

    <ng-container *ngIf="!loading">

        <h1>{{header}}</h1>

        <div style="height: 3rem"></div>

        <ng-container *ngIf="tableDataDays !== null && (mode === 'week' || mode === 'month')">

            <h2>Tagesansicht</h2>

            <nz-table class="time_table tt_days" [nzData]="tableDataDays.rows" [nzFrontPagination]="false">
                <thead>
                <tr>
                    <th *ngFor="let h of tableDataDays.header">{{h}}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let r of tableDataDays.rows">
                    <td [class.iszero]="c.str | iszerocell" *ngFor="let c of r.cells"><span>{{c.str}}</span></td>
                </tr>
                <tr class="summary" *ngIf="tableDataDays.rows.length > 0 && tableDataDays.summary !== null">
                    <td [class.iszero]="c.str | iszerocell" *ngFor="let c of tableDataDays.summary">
                        <span *ngIf="c.str !== '@sum'">{{c.str}}</span>
                        <span *ngIf="c.str === '@sum'">&sum;</span>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr class="download_row">
                    <td [colSpan]="tableDataDays.header.length">
                        <div class="download_container">
                            <button nz-button nzType="default" (click)="download(tableDataDays)"><span nz-icon nzType="file-excel"></span> Download Excel</button>
                        </div>
                    </td>
                </tr>
                </tfoot>
            </nz-table>

        </ng-container>

        <ng-container *ngIf="tableDataWeeks !== null && (mode === 'month')">

            <h2>Wochenansicht</h2>

            <nz-table class="time_table tt_days" [nzData]="tableDataWeeks.rows" [nzFrontPagination]="false">
                <thead>
                <tr>
                    <th *ngFor="let h of tableDataWeeks.header">{{h}}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let r of tableDataWeeks.rows">
                    <td [class.iszero]="c.str | iszerocell" *ngFor="let c of r.cells"><span>{{c.str}}</span></td>
                </tr>
                <tr class="summary" *ngIf="tableDataWeeks.rows.length > 0 && tableDataWeeks.summary !== null">
                    <td [class.iszero]="c.str | iszerocell" *ngFor="let c of tableDataWeeks.summary">
                        <span *ngIf="c.str !== '@sum'">{{c.str}}</span>
                        <span *ngIf="c.str === '@sum'">&sum;</span>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr class="download_row">
                    <td [colSpan]="tableDataWeeks.header.length">
                        <div class="download_container">
                            <button nz-button nzType="default" (click)="download(tableDataWeeks)"><span nz-icon nzType="file-excel"></span> Download Excel</button>
                        </div>
                    </td>
                </tr>
                </tfoot>
            </nz-table>

        </ng-container>

        <ng-container *ngIf="tableDataMonths !== null && (mode === 'year' || mode === 'total')">

            <h2>Monatsansicht</h2>

            <nz-table class="time_table tt_days" [nzData]="tableDataMonths.rows" [nzFrontPagination]="false">
                <thead>
                <tr>
                    <th *ngFor="let h of tableDataMonths.header">{{h}}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let r of tableDataMonths.rows">
                    <td [class.iszero]="c.str | iszerocell" *ngFor="let c of r.cells"><span>{{c.str}}</span></td>
                </tr>
                <tr class="summary" *ngIf="tableDataMonths.rows.length > 0 && tableDataMonths.summary !== null">
                    <td [class.iszero]="c.str | iszerocell" *ngFor="let c of tableDataMonths.summary">
                        <span *ngIf="c.str !== '@sum'">{{c.str}}</span>
                        <span *ngIf="c.str === '@sum'">&sum;</span>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr class="download_row">
                    <td [colSpan]="tableDataMonths.header.length">
                        <div class="download_container">
                            <button nz-button nzType="default" (click)="download(tableDataMonths)"><span nz-icon nzType="file-excel"></span> Download Excel</button>
                        </div>
                    </td>
                </tr>
                </tfoot>
            </nz-table>

        </ng-container>

        <ng-container *ngIf="tableDataYears !== null && (mode === 'total')">

            <h2>Jahresansicht</h2>

            <nz-table class="time_table tt_days" [nzData]="tableDataYears.rows" [nzFrontPagination]="false">
                <thead>
                <tr>
                    <th *ngFor="let h of tableDataYears.header">{{h}}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let r of tableDataYears.rows">
                    <td [class.iszero]="c.str | iszerocell" *ngFor="let c of r.cells"><span>{{c.str}}</span></td>
                </tr>
                <tr class="summary" *ngIf="tableDataYears.rows.length > 0 && tableDataYears.summary !== null">
                    <td [class.iszero]="c.str | iszerocell" *ngFor="let c of tableDataYears.summary">
                        <span *ngIf="c.str !== '@sum'">{{c.str}}</span>
                        <span *ngIf="c.str === '@sum'">&sum;</span>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr class="download_row">
                    <td [colSpan]="tableDataYears.header.length">
                        <div class="download_container">
                            <button nz-button nzType="default" (click)="download(tableDataYears)"><span nz-icon nzType="file-excel"></span> Download Excel</button>
                        </div>
                    </td>
                </tr>
                </tfoot>
            </nz-table>

        </ng-container>

        <ng-container *ngIf="tableDataProjects !== null">

            <h2>Projekte</h2>

            <nz-table class="project_table" [nzData]="tableDataProjects.rows" [nzFrontPagination]="false">
                <thead>
                <tr>
                    <th *ngFor="let h of tableDataProjects.header">{{h}}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let r of tableDataProjects.rows">
                    <td [class.iszero]="c.str | iszerocell" *ngFor="let c of r.cells"><span>{{c.str}}</span></td>
                </tr>
                <tr class="summary" *ngIf="tableDataProjects.rows.length > 0 && tableDataProjects.summary !== null">
                    <td [class.iszero]="c.str | iszerocell" *ngFor="let c of tableDataProjects.summary">
                        <span *ngIf="c.str !== '@sum'">{{c.str}}</span>
                        <span *ngIf="c.str === '@sum'">&sum;</span>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr class="download_row">
                    <td [colSpan]="tableDataProjects.header.length">
                        <div class="download_container">
                            <button nz-button nzType="default" (click)="download(tableDataProjects)"><span nz-icon nzType="file-excel"></span> Download Excel</button>
                        </div>
                    </td>
                </tr>
                </tfoot>
            </nz-table>

        </ng-container>

    </ng-container>

</epl-default-frame>
