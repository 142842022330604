import {Component, OnInit} from '@angular/core';
import {Employee} from "../../interfaces/employee";
import {ApiService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {Router} from "@angular/router";
import {showAPIError} from "../../utils/api";
import { User } from 'src/app/interfaces/user';
import {Table} from "../../interfaces/table";
import {ObjectId} from "../../interfaces/utils";

@Component({
  selector: 'epl-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  userData: User[] = [];
  tables: Table[] = [];

  newUser_username: string =  '';
  newUser_password: string = '';
  newUser_isAdmin: boolean = false;
  newUser_table: ObjectId|null = null;

  currentEditingUser: ObjectId|null = null;
  currentEditingUser_username: string = '';
  currentEditingUser_isAdmin: boolean = false;
  currentEditingUser_table: ObjectId|null = null;

  currentEditingPass: ObjectId|null = null;
  currentEditingPass_password: string = '';

  constructor(private apiservice: ApiService,
              private notification: NzNotificationService,
              private router: Router) { }

  ngOnInit(): void {
    this.getUsers().then(() => {});
  }

  async addUser() {
    if (!this.newUser_isAdmin && !this.newUser_table) return;

    try {
      const newuser = await this.apiservice.createUser(
          this.newUser_username,
          this.newUser_password,
          this.newUser_isAdmin,
          this.newUser_isAdmin ? null : this.newUser_table);

      this.userData = [newuser, ...this.userData];
      this.newUser_username = '';
      this.newUser_password = '';
      this.newUser_isAdmin = false;
      this.newUser_table = null;
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
    }
  }

  async getUsers() {
    try {
      this.tables = (await this.apiservice.getTables()).tables;
      this.userData = (await this.apiservice.listUsers()).users;
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
    }
  }

  convertTableName(tid: ObjectId): string {
    for (const table of this.tables) {
      if (tid === table.id) return table.header;
    }
    return tid
  }

  startEditUser(data: User) {
    this.currentEditingPass = null;
    this.currentEditingUser = data.userId;
    this.currentEditingUser_username = data.username;
    this.currentEditingUser_isAdmin = data.isAdmin;
    this.currentEditingUser_table = data.visibleTables[0] ?? null;
  }

  async editUser() {
    if (this.currentEditingUser === null) return;
    try {
      const d = await this.apiservice.updateUserData(
          this.currentEditingUser,
          this.currentEditingUser_username,
          this.currentEditingUser_isAdmin,
          this.currentEditingUser_isAdmin ? null : this.currentEditingUser_table);

      this.currentEditingUser = null;
      this.currentEditingPass = null;
      this.userData = this.userData.map(p => (p.userId === d.userId) ? d : p);
    } catch (err) {
      showAPIError(this.notification, 'Benutzer konnten nicht bearbeitet werden', err);
    }
  }

  startEditPassword(data: User) {
    this.currentEditingUser = null;
    this.currentEditingPass = data.userId;
    this.currentEditingPass_password = '';
  }

  async editPassword() {
    if (this.currentEditingPass === null) return;
    try {
      const d = await this.apiservice.updateUserPass(this.currentEditingPass, this.currentEditingPass_password);
      this.currentEditingUser = null;
      this.currentEditingPass = null;
      this.userData = this.userData.map(p => (p.userId === d.userId) ? d : p);
    } catch (err) {
      showAPIError(this.notification, 'Benutzer konnten nicht bearbeitet werden', err);
    }
  }

  async deleteUser(data: User) {
    try {
      await this.apiservice.deleteUser(data.userId)
      this.userData = this.userData.filter(p => p.userId !== data.userId);
    } catch (err) {
      showAPIError(this.notification, 'Benutzer konnten nicht gelöscht werden', err);
    }
  }

  abortEdit() {
    this.currentEditingUser = null;
    this.currentEditingPass = null;
  }
}
