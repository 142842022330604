import { Component } from '@angular/core';
import {isErrorCode} from "../../utils/api";
import {ApiService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {AuthenticationService} from "../../services/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'epl-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginLoading: boolean = false;
  errorText: string = "";

  email: string = '';
  password: string = '';

  constructor(private api: ApiService,
              private notification: NzNotificationService,
              private auth: AuthenticationService,
              private router: Router,
              private activatedRoute: ActivatedRoute)
  {
    this.activatedRoute.queryParams.subscribe(async params => {
      if (this.auth.isAuthenticated()) {
        await this.router.navigate(['/'], {queryParams: {}});
      }
    });
  }

  async login() {
    try {
      this.loginLoading = true;
      this.errorText = '';
      const data = await this.api.login(this.email, this.password);

      this.auth.setSelf(data.user);

      if (data.user.isAdmin) {

        this.auth.setAuth(data.token);
        this.notification.success('Angemeldet', 'Du wurdest erfolgreich angemeldet');
        await this.router.navigate(['/'], {queryParams: {}});

      } else {

        this.auth.setAuth(data.token);
        this.notification.success('Angemeldet', 'Du wurdest erfolgreich angemeldet');

        if (data.user.visibleTables.length === 0) {
          this.notification.success('Fehler', 'Der Nutzer hat keine Blätter');
        } else {
          const sheets = await this.api.getTableHistory(data.user.visibleTables[0])
          await this.router.navigate(['/edit-sheet/' + sheets.sheets[0].sheetId], {queryParams: {}});
        }
      }

    } catch (err: any) {
      if (isErrorCode(err, 'AUTH_FAILED')) {
        this.errorText = 'Die eingegebenen Login-Daten sind nicht richtig.';
      } else if (isErrorCode(err, 'INTERNAL_ERROR')) {
        this.errorText = 'Interner Server Fehler';
      } else {
        this.errorText = 'Es ist ein Fehler aufgetreten';
      }
    } finally {
      this.loginLoading = false;
    }
  }
}
