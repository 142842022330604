import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RootPageComponent } from './pages/root-page/root-page.component';
import {HomeComponent} from "./pages/home/home.component";
import {TablesComponent} from "./pages/tables/tables.component";
import {AddTableComponent} from "./pages/add-table/add-table.component";
import {EmployeesComponent} from "./pages/employees/employees.component";
import {TableHistoryComponent} from "./pages/table-history/table-history.component";
import {EditsheetComponent} from "./pages/editsheet/editsheet.component";
import { adminAuthGuard } from './guards/admin-auth.guard';
import {anyAuthGuard} from "./guards/any-auth.guard";
import {LoginComponent} from "./pages/login/login.component";
import { ProjectsComponent } from './pages/projects/projects.component';
import {UsersComponent} from "./pages/users/users.component";
import {WeekOverviewSelectorComponent} from "./pages/week-overview-selector/week-overview-selector.component";
import {WeekOverviewComponent} from "./pages/week-overview/week-overview.component";

const routes: Routes = [
  { path: 'login',                     component: LoginComponent,                                                                         },

  { path: '',                          component: RootPageComponent,              canActivate: [anyAuthGuard],                            },
  { path: 'edit-sheet/:id',            component: EditsheetComponent,             canActivate: [anyAuthGuard],                            },

  { path: 'home',                      component: HomeComponent,                  canActivate: [adminAuthGuard],                          },
  { path: 'tables',                    component: TablesComponent,                canActivate: [adminAuthGuard],                          },
  { path: 'addTable',                  component: AddTableComponent,              canActivate: [adminAuthGuard],                          },
  { path: 'table/:id',                 component: AddTableComponent,              canActivate: [adminAuthGuard],                          },
  { path: 'employees',                 component: EmployeesComponent,             canActivate: [adminAuthGuard],                          },
  { path: 'table-history/:id',         component: TableHistoryComponent,          canActivate: [adminAuthGuard],                          },
  { path: 'projects',                  component: ProjectsComponent,              canActivate: [adminAuthGuard],                          },
  { path: 'users',                     component: UsersComponent,                 canActivate: [adminAuthGuard],                          },
  { path: 'overview-weeks',            component: WeekOverviewSelectorComponent,  canActivate: [adminAuthGuard], data: {'mode': 'week'}   },
  { path: 'overview-months',           component: WeekOverviewSelectorComponent,  canActivate: [adminAuthGuard], data: {'mode': 'month'}, },
  { path: 'overview-years',            component: WeekOverviewSelectorComponent,  canActivate: [adminAuthGuard], data: {'mode': 'year'},  },
  { path: 'overview/week/:year/:kw',   component: WeekOverviewComponent,          canActivate: [adminAuthGuard], data: {'mode': 'week'}   },
  { path: 'overview/month/:year/:mon', component: WeekOverviewComponent,          canActivate: [adminAuthGuard], data: {'mode': 'month'}, },
  { path: 'overview/year/:year',       component: WeekOverviewComponent,          canActivate: [adminAuthGuard], data: {'mode': 'year'},  },
  { path: 'overview/total',            component: WeekOverviewComponent,          canActivate: [adminAuthGuard], data: {'mode': 'total'}, },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
