
<ng-container *ngIf="columnType !== null && column !== null && row !== null">

    <ng-container [ngSwitch]="columnType">

        <ng-container *ngSwitchCase="'employee'">
            <nz-select nzAllowClear
                       style="width: 100%; min-width: 100px"
                       nzPlaceHolder="Mitarbeiter auswählen"
                       [ngModel]="value | emptyStrToNull"
                       (ngModelChange)="onCellUpdated($event??'')">
                <nz-option *ngFor="let employee of employees" [nzValue]="employee.employeeId" [nzLabel]="employee.firstname + ' ' + employee.lastname"></nz-option>
            </nz-select>
        </ng-container>

        <ng-container *ngSwitchCase="'check'">
            <nz-checkbox-wrapper>
                <label nz-checkbox
                       [ngModel]="value === 'true'"
                       (ngModelChange)="onCellUpdated($event ? 'true' : 'false')"></label>
            </nz-checkbox-wrapper>
        </ng-container>

        <ng-container *ngSwitchCase="'date'">
            <nz-date-picker nzFormat="dd.MM.yyyy" style="width: 100%"
                            [ngModel]="value | emptyStrToNull"
                            (ngModelChange)="onCellUpdated($event??'')"></nz-date-picker>
        </ng-container>

        <ng-container *ngSwitchCase="'number'">
            <nz-input-number nzSize="small" [nzFormatter]="formatterNumber"
                             [ngModel]="value | emptyStrToNull"
                             (ngModelChange)="onCellUpdated($event??'')">
            </nz-input-number>
        </ng-container>

        <ng-container *ngSwitchCase="'percentage'">
            <nz-input-number-group nzAddOnAfter="%">
                <nz-input-number nzSize="small"
                                 style="width: 80px"
                                 [nzPrecision]="2"
                                 [nzStep]="1"
                                 [nzMin]="0.0"
                                 [nzMax]="100.0"
                                 [ngModel]="value | emptyStrToNull"
                                 (ngModelChange)="onCellUpdated(($event??'').toString())"></nz-input-number>
            </nz-input-number-group>
        </ng-container>

        <ng-container *ngSwitchCase="'select'">
            <nz-select style="width: 100%"
                       nzAllowClear
                       [ngModel]="value | emptyStrToNull"
                       (ngModelChange)="onCellUpdated($event??'')" >
                <nz-option *ngFor="let option of column.selectValues ?? []" [nzValue]="option" [nzLabel]="option"></nz-option>
            </nz-select>
        </ng-container>

        <ng-container *ngSwitchCase="'project'">
            <nz-select nzShowSearch
                       nzAllowClear
                       nzPlaceHolder="Bauvorhaben auswählen"
                       style="width: 100%; min-width: 100px"
                       [ngModel]="value | emptyStrToNull"
                       (ngModelChange)="onCellUpdated($event??'')">
                <nz-option *ngFor="let option of projects" [nzValue]="option.projectId" [nzLabel]="option.name"></nz-option>
            </nz-select>
        </ng-container>

        <ng-container *ngSwitchCase="'element'">
            <nz-select nzShowSearch
                       nzAllowClear
                       nzPlaceHolder="Element auswählen"
                       style="width: 100%; min-width: 165px"
                       [ngModel]="value | emptyStrToNull"
                       (ngModelChange)="onCellUpdated($event??'')">
                <nz-option *ngFor="let option of row.calculated.projectElements" [nzValue]="option.elementId" [nzLabel]="option.name"> </nz-option>
            </nz-select>
        </ng-container>

        <ng-container *ngSwitchCase="'elementArea'">
            <span>{{row.calculated.area | numberFixed:2}}</span>
        </ng-container>

        <ng-container *ngSwitchCase="'elementVolume'">
            <span>{{row.calculated.volume | numberFixed:2}}</span>
        </ng-container>

        <ng-container *ngSwitchCase="'elementIdent'">
            <span>{{row.calculated.element?.ident ?? '-'}}</span>
        </ng-container>

        <ng-container *ngSwitchDefault>
            <input nz-input nzSize="small" placeholder="{{column.header}}"
                   [ngModel]="value"
                   (ngModelChange)="onCellUpdated($event)"/>
        </ng-container>

        <ng-container *ngSwitchCase="'rownumber'">
            <span>{{index+1}}</span>
        </ng-container>

    </ng-container>

</ng-container>
