import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {AuthenticationService} from "../../services/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {showAPIError} from "../../utils/api";

@Component({
  selector: 'epl-root-page',
  templateUrl: './root-page.component.html',
  styleUrls: ['./root-page.component.scss']
})
export class RootPageComponent implements OnInit {

  constructor(private apiservice: ApiService,
              private notification: NzNotificationService,
              private authservice: AuthenticationService,
              private router: Router,
              private activatedRoute: ActivatedRoute)
  {
    //
  }


  async ngOnInit() {

    let user = this.authservice.getSelf();
    if (user === null) {
      try {

        const selfid = this.authservice.getSelfID();
        if (selfid === null) {
          await this.router.navigate(['/login']);
          return;
        }

        const data = await this.apiservice.getUser(selfid);
        this.authservice.setSelf(data);
        user = data;
      } catch (err) {
        showAPIError(this.notification, 'Benutzer konnte nicht geladen werden', err);
        return;
      }
    }

    if (user.isAdmin) {
      await this.router.navigate(['/home'], {queryParams: {}});
    } else {
      if (user.visibleTables.length === 0) {
        this.notification.success('Fehler', 'Der Nutzer hat keine Blätter');
      } else {
        const sheets = await this.apiservice.getTableHistory(user.visibleTables[0])
        await this.router.navigate(['/edit-sheet/' + sheets.sheets[0].sheetId], {queryParams: {}});
      }
    }
  }

}
