
<nz-layout class="app-layout layout-admin" style="width: 100vw">
  <nz-sider *ngIf="user?.isAdmin ?? false"
            class="menu-sidebar"
            nzCollapsible
            nzWidth="256px"
            [class]="isCollapsed() ? 'collapsed' : ''"
            [nzCollapsed]="isCollapsed()"
            (nzCollapsedChange)="setCollapsed($event);"
            [nzTrigger]="null">
    <div class="sidebar-logo">
      <fa-icon icon="hammer"></fa-icon>
      <h1>eProduktionsliste</h1>
    </div>
    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed()">

      <li nz-menu-item nzMatchRouter><a routerLink="/home"            ><fa-icon class="menu-icn" [icon]="['fas', 'gauge']"            ></fa-icon><span class="menu-txt">Übersicht</span></a></li>
      <li nz-menu-item nzMatchRouter><a routerLink="/tables"          ><fa-icon class="menu-icn" [icon]="['fas', 'user-gear']"        ></fa-icon><span class="menu-txt">Tabellenverwaltung</span></a></li>

      <li nz-menu-item nzMatchRouter><a routerLink="/overview-weeks"  ><fa-icon class="menu-icn" [icon]="['fas', 'calendar']"         ></fa-icon><span class="menu-txt">Wochenübersicht</span></a></li>
      <li nz-menu-item nzMatchRouter><a routerLink="/overview-months" ><fa-icon class="menu-icn" [icon]="['fas', 'calendar']"         ></fa-icon><span class="menu-txt">Monatsübersicht</span></a></li>
      <li nz-menu-item nzMatchRouter><a routerLink="/overview-years"  ><fa-icon class="menu-icn" [icon]="['fas', 'calendar']"         ></fa-icon><span class="menu-txt">Jahresübersicht</span></a></li>
      <li nz-menu-item nzMatchRouter><a routerLink="/overview/total"  ><fa-icon class="menu-icn" [icon]="['fas', 'calendar']"         ></fa-icon><span class="menu-txt">Gesamtübersicht</span></a></li>

      <li nz-menu-item nzMatchRouter><a routerLink="/employees"       ><fa-icon class="menu-icn" [icon]="['fas', 'building']"         ></fa-icon><span class="menu-txt">Mitarbeiterverwaltung</span></a></li>
      <li nz-menu-item nzMatchRouter><a routerLink="/projects"        ><fa-icon class="menu-icn" [icon]="['fas', 'rectangle-history']"></fa-icon><span class="menu-txt">Projekte</span></a></li>
      <li nz-menu-item nzMatchRouter><a routerLink="/users"           ><fa-icon class="menu-icn" [icon]="['fas', 'users']"            ></fa-icon><span class="menu-txt">Benutzer</span></a></li>

    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header" style="display: flex; justify-content: space-between;">
        <span class="header-trigger" (click)="setCollapsed(!isCollapsed())">
            <span *ngIf="user?.isAdmin ?? false" class="trigger" nz-icon [nzType]="isCollapsed() ? 'menu-unfold' : 'menu-fold'"></span>
            <span *ngIf="!(user?.isAdmin ?? false)" style="font-weight: bold">eProduktionsListe</span>
        </span>
        <div style="display: flex; margin-right: 24px; line-height: normal; align-items: center">
          <span class="header_userinfo">
            Angemeldet als
            <span *ngIf="user === null" nz-icon [nzType]="'loading'"></span>
            <span *ngIf="user !== null">{{user.username}}</span>
          </span>
          <span style="width: 1em"></span>
          <fa-icon-btn nzType="primary" buttonSize="32" icon="right-from-bracket" (btnClick)="logout()"></fa-icon-btn>
        </div>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <div class="inner-content-wrapper">
          <ng-content></ng-content>
        </div>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
