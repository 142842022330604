import { Pipe, PipeTransform } from '@angular/core';
import {ColumnType} from "../interfaces/table";

@Pipe({
  name: 'columnTypeToStr'
})
export class ColumnTypeToStrPipe implements PipeTransform {

  transform(value: ColumnType): string {
    switch (value) {
      case ColumnType.Text:          return 'Freitext';
      case ColumnType.Number:        return 'Zahlenwert';
      case ColumnType.Check:         return 'Checkbox';
      case ColumnType.Date:          return 'Datum';
      case ColumnType.Employee:      return 'Mitarbeiter';
      case ColumnType.Select:        return 'Auswahl';
      case ColumnType.Percentage:    return 'Prozent';
      case ColumnType.Project:       return 'Bauvorhaben';
      case ColumnType.Element:       return 'Element';
      case ColumnType.ElementArea:   return 'Elementfläche';
      case ColumnType.ElementVolume: return 'Elementvolumen';
      case ColumnType.ElementIdent:  return 'Element-Ident';
      case ColumnType.RowNumber:     return 'Zeilen-Nr';
    }

    return "??? [" + value + "] ???";
  }

}
