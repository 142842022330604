import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { de_DE } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import de from '@angular/common/locales/de';
import { FormsModule } from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FilesizePipe } from './pipes/filesize.pipe';
import { IconsProviderModule } from './utils/icons-provider.module';
import { DisplaydurationPipe } from './pipes/displayduration.pipe';
import { DisplayDatePipe } from './pipes/displaydate.pipe';
import { SuffixPipe } from './pipes/suffix';
import { IconBtnComponent } from './components/icon-btn/icon-btn.component';
import { TrustHTMLPipe } from './pipes/trust-html.pipe';
import { TrustResourceURLPipe } from './pipes/trust-resourceurl.pipe';
import { TrustScriptPipe } from './pipes/trust-script.pipe';
import { TrustURLPipe } from './pipes/trust-url.pipe';
import { TrustStylePipe } from './pipes/trust-style.pipe';
import { ShortIDPipe } from './pipes/shortid';
import { NumberArrayPipe } from './pipes/number-array.pipe';
import { CastStringArrayPipe } from './pipes/cast-stringarray.pipe';
import { BooleanNotPipe } from './pipes/boolean-not';
import { CastNumberPipe } from './pipes/cast-number.pipe';
import { NoFrameComponent } from './frames/no-frame/no-frame.component';
import { CastStringPipe } from './pipes/cast-string.pipe';
import {CastDatePipe} from "./pipes/cast-date.pipe";
import {CastNumberArrayPipe} from "./pipes/cast-numberarray.pipe";
import {DefaultFrameComponent} from "./frames/default-frame/default-frame.component";
import { RootPageComponent } from './pages/root-page/root-page.component';
import { HomeComponent } from './pages/home/home.component';
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzNotificationService} from "ng-zorro-antd/notification";
import { TablesComponent } from './pages/tables/tables.component';
import {NzTableModule} from "ng-zorro-antd/table";
import {NzDividerModule} from "ng-zorro-antd/divider";
import { AddTableComponent } from './pages/add-table/add-table.component';
import {NzSelectModule} from "ng-zorro-antd/select";
import { EmployeesComponent } from './pages/employees/employees.component';
import {NzInputModule} from "ng-zorro-antd/input";
import {NzTabsModule} from "ng-zorro-antd/tabs";
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";
import { TableHistoryComponent } from './pages/table-history/table-history.component';
import { EditsheetComponent } from './pages/editsheet/editsheet.component';
import { ColumnTypeToStrPipe } from './pipes/column-type-to-str.pipe';
import {NzCheckboxModule} from "ng-zorro-antd/checkbox";
import {NzInputNumberModule} from "ng-zorro-antd/input-number";
import { LoginComponent } from './pages/login/login.component';
import {NzAlertModule} from "ng-zorro-antd/alert";
import {NzSwitchModule} from "ng-zorro-antd/switch";
import {ProjectsComponent} from "./pages/projects/projects.component";
import {NzModalModule} from "ng-zorro-antd/modal";
import { UsersComponent } from './pages/users/users.component';
import {NzPopconfirmModule} from "ng-zorro-antd/popconfirm";
import { NumberFixedPipe } from './pipes/number-fixed.pipe';
import { WeekOverviewSelectorComponent } from './pages/week-overview-selector/week-overview-selector.component';
import { WeekOverviewComponent } from './pages/week-overview/week-overview.component';
import { EmptyStrToNullPipe } from './pipes/empty-str-to-null.pipe';
import { CellInputComponent } from './components/cell-input/cell-input.component';
import {NzSpinModule} from "ng-zorro-antd/spin";
import { IsZeroCellPipe } from './pipes/is-zero-cell.pipe';
import { FormatFloatPipe } from './pipes/format-float.pipe';

registerLocaleData(de);

@NgModule({
  declarations: [
    AppComponent,
    NoFrameComponent,
    DefaultFrameComponent,
    BooleanNotPipe,
    CastDatePipe,
    CastNumberPipe,
    CastNumberArrayPipe,
    CastStringPipe,
    CastStringArrayPipe,
    NumberArrayPipe,
    ShortIDPipe,
    TrustHTMLPipe,
    TrustResourceURLPipe,
    TrustScriptPipe,
    TrustStylePipe,
    TrustURLPipe,
    SuffixPipe,
    DisplayDatePipe,
    IconBtnComponent,
    DisplaydurationPipe,
    FilesizePipe,
    RootPageComponent,
    HomeComponent,
    TablesComponent,
    AddTableComponent,
    EmployeesComponent,
    TableHistoryComponent,
    EditsheetComponent,
    ColumnTypeToStrPipe,
    LoginComponent,
    ProjectsComponent,
    UsersComponent,
    NumberFixedPipe,
    WeekOverviewSelectorComponent,
    WeekOverviewComponent,
    EmptyStrToNullPipe,
    CellInputComponent,
    IsZeroCellPipe,
    FormatFloatPipe,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        IconsProviderModule,
        NzLayoutModule,
        NzMenuModule,
        FontAwesomeModule,
        NzButtonModule,
        NzTableModule,
        NzDividerModule,
        NzSelectModule,
        NzInputModule,
        NzTabsModule,
        NzDatePickerModule,
        NzCheckboxModule,
        NzInputNumberModule,
        NzAlertModule,
        NzSwitchModule,
        NzModalModule,
        NzPopconfirmModule,
        NzSpinModule,
    ],
  providers: [
    { provide: NZ_I18N, useValue: de_DE },
    NzNotificationService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
