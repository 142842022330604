import {Component, OnInit} from '@angular/core';
import {Employee} from "../../interfaces/employee";
import {Table} from "../../interfaces/table";
import {ApiService} from "../../services/api.service";
import {Router} from "@angular/router";
import {showAPIError} from "../../utils/api";
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: 'epl-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {

  employeeData: Employee[] = [];

  newEmployee: Employee = {
    firstname: '',
    lastname: '',
    abbreviation: '',
  }

  editedEmployee?: Employee;

  constructor(private apiservice: ApiService,
              private notification: NzNotificationService,
              private router: Router) { }

  ngOnInit(): void {
    this.getEmployees().then(() => {});
  }

  async addEmployee() {

    if (this.newEmployee.firstname === '' || this.newEmployee.lastname === '' || this.newEmployee.abbreviation === '') {
      this.notification.info("Fehler", `Bitte Daten eingeben`);
      return;
    }

    try {
      const d = await this.apiservice.addEmployee(this.newEmployee.firstname, this.newEmployee.lastname, this.newEmployee.abbreviation);
      this.employeeData = [d, ...this.employeeData];
      this.newEmployee.firstname = '';
      this.newEmployee.lastname = '';
      this.newEmployee.abbreviation = '';
    } catch (err) {
      showAPIError(this.notification, 'Mitarbeiter konnten nicht erstellt werden', err);
    }
  }

  async updateEmployee() {
    if (this.editedEmployee == undefined || this.editedEmployee.employeeId === undefined) return;

    try {
      await this.apiservice.updateEmployee(this.editedEmployee.employeeId, this.newEmployee.firstname, this.newEmployee.lastname, this.newEmployee.abbreviation);
      this.editedEmployee = undefined;
    } catch (err) {
      showAPIError(this.notification, 'Mitarbeiter konnten nicht erstellt werden', err);
    }
  }

  async getEmployees() {
    try {
      this.employeeData = (await this.apiservice.getEmployees()).employees;
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
    }
  }

  editEmployee(data: Employee) {
    this.editedEmployee = data;
  }

  async deleteEmployee(data: Employee) {
    if (data.employeeId === undefined) return;

    try {
      const rm = await this.apiservice.deleteEmployee(data.employeeId);
      this.employeeData = this.employeeData.filter(p => p.employeeId !== data.employeeId);
      this.notification.info("Mitarbeiter gelöscht", `Der Mitarbeiter ${rm.firstname} ${rm.lastname} wurde gelöscht`);
    } catch (err) {
      showAPIError(this.notification, 'Mitarbeiter konnten nicht erstellt werden', err);
    }
  }

  cancelEdit() {
    this.editedEmployee = undefined;
  }
}