<epl-default-frame>

  <div class="fullpageloader" *ngIf="loading">
    <nz-spin></nz-spin>
  </div>

  <ng-container *ngIf="!loading && table !== null">

    <h1 class="pageheader">
      <span>{{table.header}}</span>

      <button *ngIf="isPastDate" nz-button style="margin-left: 2rem" (click)="openLatestSheet()"><span nz-icon nzType="fast-forward"></span> Zum aktuellen Blatt springen</button>

      <span class="grow"></span>

      <ng-container *ngIf="sheet !== null && tableMinDate !== null && tableMaxDate !== null">
        <span *ngIf="  user?.isAdmin ?? false " class="h1_date_edit" (click)="dpicker.open()">{{sheet.created | date:'dd.MM.yyyy'}}</span>
        <span *ngIf="!(user?.isAdmin ?? false)" class="h1_date"                              >{{sheet.created | date:'dd.MM.yyyy'}}</span>
        <nz-date-picker #dpicker
                        class="datechooser"
                        [nzShowNow]="false"
                        [nzShowToday]="true"
                        [nzAllowClear]="false"
                        [nzDisabledDate]="dateNotInRange"
                        [ngModel]="sheet.created"
                        (ngModelChange)="changeDate($event)"></nz-date-picker>
      </ng-container>
    </h1>

    <nz-table [nzData]="table.columns" [nzFrontPagination]="false">
      <thead>
      <tr>
        <ng-container *ngFor="let column of table.columns">
          <th class="table_datacol_th" *ngIf="column.width === ''" style="white-space: pre" [style.width]="'auto'">{{column.header}}</th>
          <th class="table_datacol_th" *ngIf="column.width !== ''" style="white-space: pre" [style.width]="column.width">{{column.header}}</th>
        </ng-container>
        <th style="width: 115px;"><!-- action buttons --></th>
      </tr>
      </thead>
      <tbody>

      <tr *ngFor="let row of rows; index as idx">

        <ng-container *ngIf="editRow === null || editRow.rowId !== row.rowId">

          <td *ngFor="let col of table.columns">

            <ng-container *ngIf="row.cells[col.columnId] !== undefined || col.type === 'rownumber'">

              <ng-container *ngIf="col.type === 'check'">
                <nz-checkbox-wrapper>
                  <label nz-checkbox [ngModel]="row.cells[col.columnId].value === 'true'" [disabled]="true"></label>
                </nz-checkbox-wrapper>
              </ng-container>

              <ng-container *ngIf="col.type !== 'check'">
                {{getCellDisplayValue(col, row, idx)}}
              </ng-container>

            </ng-container>

            <ng-container *ngIf="!(row.cells[col.columnId] !== undefined || col.type === 'rownumber')" >
                <div style="background-color: #FDD; display: flex; justify-content: center; align-items: center">&#8709;</div>
            </ng-container>

          </td>
          <td>
            <div class="actionbuttons">
              <ng-container *ngIf="(user?.isAdmin ?? false) || (!isPastDate && allowNormalUserEdit)">
                <button *ngIf="editRow === null"
                        nz-button nzType="default" nzDanger nzSize="small"
                        nz-popconfirm
                        nzPopconfirmTitle="Diese Zeile wirklich nachträglich bearbeiten?"
                        (nzOnConfirm)="editOldRow(row)"
                        nzPopconfirmPlacement="top">
                  <span nz-icon nzType="edit"></span>
                </button>
              </ng-container>
            </div>
          </td>

        </ng-container>

        <ng-container *ngIf="editRow !== null && editRow.rowId === row.rowId">

          <td *ngFor="let col of table.columns">

            <ng-container *ngIf="editRow.cells[col.columnId] !== undefined">

              <epl-cell-input style="display: block; width: 100%;"
                              [columnType]="col.type"
                              [index]="idx"
                              [row]="editRow"
                              [value]="editRow.cells[col.columnId].value"
                              [column]="col"
                              [employees]="employees"
                              [projects]="projects"
                              (onChanged)="editRow.cells[col.columnId].value = $event; onEditRowCellUpdated(col)"></epl-cell-input>

            </ng-container>

          </td>
          <td>
            <div class="actionbuttons">
              <button nz-button nzType="primary" nzSize="small"
                      nz-popconfirm
                      nzPopconfirmTitle="Zeile aktualisieren?"
                      (nzOnConfirm)="submitUpdatedOldRow()"
                      nzPopconfirmPlacement="top">
                <span nz-icon nzType="check"></span>
              </button>
              <button nz-button nzType="default" nzDanger nzSize="small"
                      (click)="cancelEditOldRow()">
                <span nz-icon nzType="close"></span>
              </button>
            <button nz-button nzType="primary" nzDanger nzSize="small"
                    nz-popconfirm
                    nzPopconfirmTitle="Zeile wirklich permanent löschen?"
                    nzOkDanger="true"
                    (nzOnConfirm)="deleteOldRow()"
                    nzPopconfirmPlacement="top">
              <span nz-icon nzType="delete"></span>
            </button>
            </div>
          </td>

        </ng-container>

      </tr>

      <tr *ngIf="(user !== null && user.isAdmin) || !isPastDate || pastDateAdminOverride">

        <td *ngFor="let column of table.columns" >

          <ng-container *ngIf="(!isPastDate || pastDateAdminOverride) && newRow != undefined && newRow.cells != undefined && newRow.cells[column.columnId] != undefined">

            <epl-cell-input style="display: block; width: 100%;"
                            [columnType]="column.type"
                            [index]="rows.length"
                            [row]="newRow"
                            [value]="newRow.cells[column.columnId].value"
                            [column]="column"
                            [employees]="employees"
                            [projects]="projects"
                            (onChanged)="newRow.cells[column.columnId].value = $event; onCellUpdated(newRow, column)"></epl-cell-input>

          </ng-container>

        </td>
        <td>
          <div class="actionbuttons">

            <button *ngIf="(!isPastDate || pastDateAdminOverride)"
                    nz-button nzType="primary" nzSize="small"
                    (click)="submitRow()" style="width: 100%">
              <span nz-icon nzType="plus"></span>
            </button>

            <button *ngIf="(user !== null && user.isAdmin) && (isPastDate && !pastDateAdminOverride)"
                    nz-button nzType="default" nzSize="small" nzDanger
                    nz-popconfirm
                    nzPopconfirmTitle="Dieses Arbeitsblatt ist nicht das heutige - trotzdem bearbeiten?"
                    (nzOnConfirm)="pastDateAdminOverride = true"
                    nzPopconfirmPlacement="top"
                    style="width: 100%">
              <span nz-icon nzType="lock"></span>
            </button>

          </div>
        </td>
      </tr>

      <ng-container *ngIf="table.enableSummaryRows">

        <ng-container *ngFor="let summary of summaries; let i = index">

        <tr class="summary_seperator">
          <td *ngFor="let _ of table.columns"></td>
          <td><!-- actions --></td>
        </tr>

        <tr class="summary">
          <td *ngFor="let column of table.columns">
            <div class="td-data">
              <ng-container *ngIf="column.type == 'project'">
                <span>{{summary.project.name}}</span>
              </ng-container>
              <ng-container *ngIf="column.type == 'percentage'">
                <span class="hidden">{{summary.percentageSum | numberFixed:2}} %</span>
              </ng-container>
              <ng-container *ngIf="column.type == 'elementArea'">
                <span class="hidden">{{summary.areaPerc | numberFixed:2}} %</span>
                <span>{{summary.areaSum | numberFixed:2}} / {{summary.areaTotal | numberFixed:2}} m²</span>
              </ng-container>
              <ng-container *ngIf="column.type == 'elementVolume'">
                <span class="hidden">{{summary.volumePerc | numberFixed:2}} %</span>
                <span>{{summary.volumeSum | numberFixed:2}} / {{summary.volumeTotal | numberFixed:2}} m³</span>
              </ng-container>
            </div>
          </td>

          <td><!-- actions --></td>
        </tr>
        </ng-container>

      </ng-container>

      </tbody>
    </nz-table>

    <div class="extra_data" *ngIf="user !== null && user.isAdmin">

      <div class="time_data" *ngIf="table.enableHours">
        <label>Anzahl der mitarbeitenden Personen: </label><span><nz-input-number nzSize="small" [nzFormatter]="formatterNumber" [ngModel]="sheet!.numberWorkers" (ngModelChange)="sheet!.numberWorkers = $event; sheetDirty = true;"></nz-input-number></span>
        <label>Leistungsstunden Büro:              </label><span><nz-input-number nzSize="small" [nzFormatter]="formatterNumber" [ngModel]="sheet!.hoursOffice"   (ngModelChange)="sheet!.hoursOffice   = $event; sheetDirty = true;"></nz-input-number></span>
        <label>Leistungsstunden Betrieb:           </label><span><nz-input-number nzSize="small" [nzFormatter]="formatterNumber" [ngModel]="sheet!.hoursFactory"  (ngModelChange)="sheet!.hoursFactory  = $event; sheetDirty = true;"></nz-input-number></span>
        <label>Leistungsstunden Dübelproduktion:   </label><span><nz-input-number nzSize="small" [nzFormatter]="formatterNumber" [ngModel]="sheet!.hoursDowel"    (ngModelChange)="sheet!.hoursDowel    = $event; sheetDirty = true;"></nz-input-number></span>
        <label>Leistungsstunden Sonstiges:         </label><span><nz-input-number nzSize="small" [nzFormatter]="formatterNumber" [ngModel]="sheet!.hoursOther"    (ngModelChange)="sheet!.hoursOther    = $event; sheetDirty = true;"></nz-input-number></span>
        <fa-icon-btn [disabled]="!sheetDirty" nzType="primary" buttonSize="24" icon="save" (btnClick)="updateSheet()" text="Speichern"></fa-icon-btn>
      </div>

      <span class="grow"></span>

      <button nz-button nzType="default" (click)="downloadExcel()"><span nz-icon nzType="file-excel"></span> Download Excel</button>

    </div>
  </ng-container>
</epl-default-frame>


