import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'emptyStrToNull', pure: true})
export class EmptyStrToNullPipe implements PipeTransform {

  transform(value: string): string|null {
    return (value === '') ? null : value;
  }

}
