import {HttpErrorResponse} from '@angular/common/http';
import {isAPIError} from "../interfaces/api";
import {NzNotificationService} from "ng-zorro-antd/notification";

export function showAPIError(nzns: NzNotificationService, txt: string, err: any) {

  console.error('[[API-ERROR]]', err);

  if (err == null) {
    nzns.error('Fehler', txt + '<br/><br/>' + 'Ein unbekannter Fehler ist aufgetreten');
    return
  }

  if (!(err instanceof HttpErrorResponse)) {
    nzns.error('Fehler', txt + '<br/><br/>' + 'Ein unbekannter Fehler ist aufgetreten');
    return
  }

  if (!isAPIError(err.error)) {
    nzns.error('Fehler', txt + '<br/><br/>' + 'Ein unbekannter Fehler ist aufgetreten');
    return
  }

  if (isErrorCode(err, ['INTERNAL_ERROR', 'PANIC', 'DATABASE_ERROR', 'FILESYSTEM_ERROR'])) {
    nzns.error('Fehler', txt + '<br/><br/>' + 'Es ist ein interner Fehler auf dem Server aufgetreten');
    return
  }

  if (isErrorCode(err, 'SELF_DELETE')) {
    nzns.error('Fehler', txt + '<br/><br/>' + 'Der aktuelle Benutzer kann nicht gelöscht werden');
    return
  }

  if (isErrorCode(err, 'USERNAME_COLLISION')) {
    nzns.error('Fehler', txt + '<br/><br/>' + 'Der Benutzername ist bereits vergeben');
    return
  }

  if (isErrorCode(err, 'EMAIL_COLLISION')) {
    nzns.error('Fehler', txt + '<br/><br/>' + 'Die Mail Adresse ist bereits vergeben');
    return
  }

  if (isErrorCode(err, 'MISSING_PERMISSIONS')) {
    nzns.error('Fehler', txt + '<br/><br/>' + 'Für diese Aktion hat der aktuelle Benutzer keine ausreichenden Berechtigungen');
    return
  }

  if (isErrorCode(err, 'WRONG_OLD_PASSWORD')) {
    nzns.error('Fehler', txt + '<br/><br/>' + 'Das aktuelle Passwort ist nicht korrekt');
    return
  }

  if (isErrorCode(err, ['UNAUTHORIZED', 'AUTH_FAILED'])) {
    nzns.error('Fehler', txt + '<br/><br/>' + 'AUthentifizierung mit dem Server fehlgeschlagen');
    return

  }

  nzns.error('Fehler', txt + '<br/><br/>' + formatAPIError(err));
}

export function formatAPIError(err: any): string {

  if (!(err instanceof HttpErrorResponse)) {
    return "API Request fehlgeschlagen";
  }

  const apierr = err.error;

  if (!isAPIError(apierr)) {
    return err.message;
  }

  return apierr.message;
}

export function isErrorCode(err: any, errcode: string | string[]): boolean {

  if (err.error === undefined) return false;

  if (!isAPIError(err.error)) {
    return false;
  }

  if (typeof errcode === 'string')
    return err.error.errorcode === errcode;
  else
    return errcode.some(p => err.error.errorcode === p);
}
