<nz-modal [nzVisible]="showImportModal"
          nzTitle="Projekt aus XLS importieren"
          nzOkText="Import"
          (nzOnOk)="doImport()"
          [nzOkDisabled]="importPreviewElements === null || importPreviewElements.length === 0 || importProjectname === ''"
          [nzOkLoading]="importLoading"
          (nzOnCancel)="showImportModal = false">
  <div *nzModalContent class="import-modal-content">

    <button class="btnUpload" nz-button (click)="uploadInput.click()"><span nz-icon nzType="upload"></span>Datei hochladen</button>

    <input style="display: none" #uploadInput type="file" (change)="onImportFileSelected($event)" />

    <span  class="lblName">Projektname</span>
    <input class="edName" nz-input placeholder="Projektname" [(ngModel)]="importProjectname" />

    <div class="tablElements">
      <nz-table *ngIf="importPreviewElements !== null" [nzFrontPagination]="false" [nzData]="importPreviewElements" [nzLoading]="importPreviewLoading">
        <thead>
        <tr>
          <th>Name</th>
          <th>Ident</th>
          <th>Fläche (m²)</th>
          <th>Volumen (m³)</th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let element of importPreviewElements">
          <td>{{element.name}}</td>
          <td>{{element.ident}}</td>
          <td>{{element.area}}</td>
          <td>{{element.volume}}</td>
        </tr>
        </tbody>
      </nz-table>
    </div>


  </div>
</nz-modal>

<nz-modal [nzVisible]="showElementsProject !== null"
          nzTitle="Projekt {{showElementsProject?.name}} anzeigen"
          (nzOnOk)="showElementsProject = null"
          (nzOnCancel)="showElementsProject = null">
  <div *nzModalContent class="show-elements-modal-content">

    <div class="tablElements">
      <nz-table [nzFrontPagination]="false" [nzData]="showElementsData" [nzLoading]="showElementsLoading">
        <thead>
        <tr>
          <th>Name</th>
          <th>Ident</th>
          <th>Fläche (m²)</th>
          <th>Volumen (m³)</th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let element of showElementsData">
          <td>{{element.name}}</td>
          <td>{{element.ident}}</td>
          <td>{{element.area}}</td>
          <td>{{element.volume}}</td>
        </tr>
        </tbody>
      </nz-table>
    </div>


  </div>
  <div *nzModalFooter>
    <div style="display:grid; grid-template-columns: 1fr auto;">
      <span></span>
      <button nz-button nzType="primary" (click)="showElementsProject = null" [nzLoading]="loading">Schließen</button>
    </div>
  </div>
</nz-modal>


<epl-default-frame>

  <div style="display: grid; grid-template-columns: auto 1fr auto">
    <h1>Projektverwaltung</h1>
    <span></span>
    <button nz-button nzType="primary" (click)="openImportModal()"><span nz-icon nzType="plus"></span>Neues Projekt importieren</button>
  </div>

  <nz-table #projectsTable [nzData]="projects" [nzLoading]="loading" [nzFrontPagination]="false">

    <thead>
    <tr>
      <th>ID</th>
      <th>Name</th>
      <th>Dateiname</th>
      <th>Elemente</th>
      <th>Import-Datum</th>
      <th></th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let data of projects">
      <td>{{data.projectId | shortid}}</td>
      <td>{{data.name}}</td>
      <td>{{data.filename}}</td>
      <td>{{data.elementCount}}</td>
      <td>{{data.created | displaydate}}</td>
      <td style="white-space: pre;">
        <button nz-button (click)="viewProject(data)" style="margin-left: 0.5rem"><span nz-icon nzType="eye"></span>Elemente anschauen</button>
        <button nz-button nz-popconfirm nzPopconfirmTitle="Projekt wirklich löschen?" (nzOnConfirm)="deleteProject(data)" style="margin-left: 0.5rem" nzDanger>
          <span nz-icon nzType="delete"></span>Löschen
        </button>
      </td>
    </tr>
    </tbody>

  </nz-table>

</epl-default-frame>
