
<button nz-button
        [nzType]="nzType"
        [nzDanger]="nzDanger"
        nzSize="small"
        [disabled]="disabled"
        [style.font-size]="iconSize + 'px'"
        [style.display]="'inline-flex'"
        [style.align-items]="'center'"
        [style.height]="buttonSize + 'px'"
        style="padding: 0; height: auto"
        (click)="click($event)">

  <span *ngIf="loading || icon !== null"
        [style.height]="'calc( ' + buttonSize + 'px - 2px )'"
        [style.width]="'calc( ' + buttonSize + 'px - 2px )'"
        [style.display]="'inline-flex'"
        [style.justify-content]="'center'"
        [style.align-items]="'center'">

    <fa-icon *ngIf="!loading && icon !== null" [style.opacity]="opacity" [icon]="icon"                         ></fa-icon>
    <fa-icon *ngIf=" loading"                  [style.opacity]="opacity" [icon]="'loader'" [animation]="'spin'"></fa-icon>

  </span>

  <span *ngIf="text !== ''"
        [style.margin-left]="(loading || icon !== null) ? '0' : '0.5rem'"
        [style.margin-right]="'0.5rem'"
        [style.font-size]="textSize + 'px'" >{{text}}</span>

</button>
