import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {lastValueFrom, Observable} from "rxjs";
import {ColumnDefinition, Table} from "../interfaces/table";
import {Employee} from "../interfaces/employee";
import {CreateRow, Row, Sheet, UpdateRow} from "../interfaces/sheet";
import {environment} from "../../environments/environment";
import { AuthenticationService } from './authentication.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {APIMethod, APIRequestMethod, APIServiceRef} from "../utils/apiMethod";
import {CursorToken, JWTToken, ObjectId} from "../interfaces/utils";
import {User} from "../interfaces/user";
import {Element} from "../interfaces/project";
import {showAPIError} from "../utils/api";
import {Project} from "../interfaces/project";
import {RFC3339} from "../interfaces/datetime";
import {SheetOverview} from "../interfaces/sheetOverview";
import {DateUtils} from "../utils/date";
import {CreateExcelData} from "../interfaces/excel";

type Pagination = {nextPageToken: CursorToken, pageSize: number};

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private static ENDPOINTS = {

    login:                new APIMethod<{token: JWTToken, user: User}>          (APIRequestMethod.POST,    'login'                        ),
    tokenRefresh:         new APIMethod<{token: JWTToken, user: User}>          (APIRequestMethod.POST,    'refresh'                      ),

    listUsers:            new APIMethod<{users: User[]}>                        (APIRequestMethod.GET,     'user'                         ),
    getUser:              new APIMethod<User>                                   (APIRequestMethod.GET,     'user/{uid}'                   ),
    deleteUser:           new APIMethod<{}>                                     (APIRequestMethod.DELETE,  'user/{uid}'                   ),
    createUser:           new APIMethod<User>                                   (APIRequestMethod.POST,    'user'                         ),
    updateUser:           new APIMethod<User>                                   (APIRequestMethod.PATCH,   'user/{uid}'                   ),

    getTable:             new APIMethod<Table>                                  (APIRequestMethod.GET,     'table/{tableid} '             ),
    listTables:           new APIMethod<{tables: Table[]}>                      (APIRequestMethod.GET,     'table'                        ),
    createTable:          new APIMethod<Table>                                  (APIRequestMethod.POST,    'table'                        ),
    updateTable:          new APIMethod<Table>                                  (APIRequestMethod.PATCH,   'table/{tableid}'              ),
    deleteTable:          new APIMethod<Table>                                  (APIRequestMethod.DELETE,  'table/{tableid}'              ),
    listTableSheets:      new APIMethod<Pagination & {sheets:Sheet[]}>          (APIRequestMethod.GET,     'table/{tableid}/sheets'       ),

    listEmployees:        new APIMethod<{ employees: Employee[] }>              (APIRequestMethod.GET,     'employee'                     ),
    getEmployee:          new APIMethod<Employee>                               (APIRequestMethod.GET,     'employee/{emplid}'            ),
    addEmployee:          new APIMethod<Employee>                               (APIRequestMethod.POST,    'employee'                     ),
    updateEmployees:      new APIMethod<Employee>                               (APIRequestMethod.PATCH,   'employee/{emplid}'            ),
    deleteEmployees:      new APIMethod<Employee>                               (APIRequestMethod.DELETE,  'employee/{emplid}'            ),

    listSheets:           new APIMethod<Pagination & {sheets:Sheet[]}>          (APIRequestMethod.GET,     'sheet'                        ),
    getSheet:             new APIMethod<Sheet>                                  (APIRequestMethod.GET,     'sheet/{sheetid}'              ),
    updateSheet:          new APIMethod<Sheet>                                  (APIRequestMethod.PATCH,   'sheet/{sheetid}'              ),

    createRow:            new APIMethod<Row>                                    (APIRequestMethod.POST,    'sheet/{sheetid}/row'          ),
    getRows:              new APIMethod<{rows: Row[]}>                          (APIRequestMethod.GET,     'sheet/{sheetid}/rows'         ),
    updateRow:            new APIMethod<Row>                                    (APIRequestMethod.PUT,     'sheet/{sheetid}/rows/{rowid}' ),
    deleteRow:            new APIMethod<{}>                                     (APIRequestMethod.DELETE,  'sheet/{sheetid}/rows/{rowid}' ),

    listRows:             new APIMethod<Pagination & {rows:Row[]}>              (APIRequestMethod.GET,     'row'                          ),

    createProject:        new APIMethod<{project:Project,elements:Element[]}>   (APIRequestMethod.POST,    'projects'                     ),
    listProjects:         new APIMethod<{projects: Project[]}>                  (APIRequestMethod.GET,     'projects'                     ),
    getElementsByProject: new APIMethod<{elements: Element[]}>                  (APIRequestMethod.GET,     'projects/{projectid}/elements'),
    getProject:           new APIMethod<Project>                                (APIRequestMethod.GET,     'projects/{projectid}'         ),
    deleteProject:        new APIMethod<{}>                                     (APIRequestMethod.DELETE,  'projects/{projectid}'         ),

    getAllElements:       new APIMethod<{elements: Element[]}>                  (APIRequestMethod.GET,     'elements'                     ),

    getDateRange:         new APIMethod<{min: RFC3339|null, max: RFC3339|null}> (APIRequestMethod.GET,     'dateRange'                    ),
    getOverview:          new APIMethod<{entries: SheetOverview[]}>             (APIRequestMethod.GET,     'sheetsOverview'               ),

    createExcel:          new APIMethod<{hash: string}>                         (APIRequestMethod.POST,    'excel'                        ),
  };

  private readonly ref: APIServiceRef;

  constructor(private http: HttpClient,
              private auth: AuthenticationService,
              private notification: NzNotificationService,) {
    this.ref = ({http: http, auth: auth, api: this});
  }

  getTables() {
    return ApiService.ENDPOINTS.listTables.run(this.ref, {}, {});
  }

  getTable(id: ObjectId) {
    return ApiService.ENDPOINTS.getTable.run(this.ref, {':tableid': id}, {});
  }

  createTable(header: string, columns: ColumnDefinition[], enableHours: boolean, enableElementSums: boolean, enableSummaryRows: boolean) {
    return ApiService.ENDPOINTS.createTable.run(this.ref, {}, {'header': header, 'columns': columns, 'enableHours': enableHours, 'enableElementSums': enableElementSums, 'enableSummaryRows': enableSummaryRows});
  }

  updateTable(tableid: ObjectId, header: string, columns: ColumnDefinition[], enableHours: boolean, enableElementSums: boolean, enableSummaryRows: boolean) {
    return ApiService.ENDPOINTS.updateTable.run(this.ref, {':tableid': tableid}, {'header': header, 'columns': columns, 'enableHours': enableHours, 'enableElementSums': enableElementSums, 'enableSummaryRows': enableSummaryRows});
  }

  refreshAdminToken() {
    return ApiService.ENDPOINTS.tokenRefresh.run(this.ref, {}, {});
  }

  deleteTable(tableId: ObjectId) {
    return ApiService.ENDPOINTS.deleteTable.run(this.ref, {':tableid': tableId}, {});
  }

  getTableHistory(tableId: ObjectId) {
    return ApiService.ENDPOINTS.listTableSheets.run(this.ref, {':tableid': tableId}, {});
  }

  getEmployees() {
    return ApiService.ENDPOINTS.listEmployees.run(this.ref, {}, {});
  }

  addEmployee(firstname: string, lastname: string, abbrev: string) {
    return ApiService.ENDPOINTS.addEmployee.run(this.ref, {}, {'firstname': firstname, 'lastname': lastname, 'abbreviation' : abbrev});
  }

  updateEmployee(id: ObjectId, firstname: string, lastname: string, abbrev: string) {
    return ApiService.ENDPOINTS.updateEmployees.run(this.ref, {':emplid': id}, {'firstname': firstname, 'lastname': lastname, 'abbreviation' : abbrev});
  }

  deleteEmployee(id: ObjectId) {
    return ApiService.ENDPOINTS.deleteEmployees.run(this.ref, {':emplid': id}, {});
  }

  getEmployee(id: ObjectId) {
    return ApiService.ENDPOINTS.getEmployee.run(this.ref, {':emplid': id}, {});
  }

  getSheet(id: ObjectId) {
    return ApiService.ENDPOINTS.getSheet.run(this.ref, {':sheetid': id}, {});
  }

  getRows(sheetId: ObjectId) {
    return ApiService.ENDPOINTS.getRows.run(this.ref, {':sheetid': sheetId}, {});
  }

  addRow(row: CreateRow) {
    return ApiService.ENDPOINTS.createRow.run(this.ref, {':sheetid': row.sheetId}, {'cells': row.cells});
  }

  updateExistingRow(row: UpdateRow) {
    return ApiService.ENDPOINTS.updateRow.run(this.ref, {':sheetid': row.sheetId, ':rowid': row.rowId}, {'cells': row.cells});
  }

  deleteExistingRow(sheetId: ObjectId, rowId: ObjectId) {
    return ApiService.ENDPOINTS.deleteRow.run(this.ref, {':sheetid': sheetId, ':rowid': rowId}, {});
  }

  updateSheetWorkers(sheetId: ObjectId, numberWorkers: number, hoursOffice: number, hoursFactory: number, hoursDowel: number, hoursOther: number) {
    return ApiService.ENDPOINTS.updateSheet.run(this.ref, {':sheetid': sheetId}, {
      'numberWorkers': numberWorkers,
      'hoursOffice':   hoursOffice,
      'hoursFactory':  hoursFactory,
      'hoursDowel':    hoursDowel,
      'hoursOther':    hoursOther,
    });
  }

  login(ident: string, pw: string) {
    return ApiService.ENDPOINTS.login.run(this.ref, {}, {'identifier': ident, 'password': pw});
  }

  listUsers() {
    return ApiService.ENDPOINTS.listUsers.run(this.ref, {}, {});
  }

  getUser(uid: ObjectId) {
    return ApiService.ENDPOINTS.getUser.run(this.ref, {':uid': uid}, {});
  }

  deleteUser(uid: ObjectId) {
    return ApiService.ENDPOINTS.deleteUser.run(this.ref, {':uid': uid}, {});
  }

  createProject(file: string, fileName: string, projectname: string, porcelain: boolean) {
    return ApiService.ENDPOINTS.createProject.run(this.ref, {}, {'file': file, 'filename': fileName, 'projectname': projectname, 'porcelain': porcelain});
  }

  getProjects() {
    return ApiService.ENDPOINTS.listProjects.run(this.ref, {}, {});
  }

  getElementsByProject(projectId: ObjectId) {
    return ApiService.ENDPOINTS.getElementsByProject.run(this.ref, {':projectid': projectId}, {});
  }

  getProject(projectId: ObjectId) {
    return ApiService.ENDPOINTS.getProject.run(this.ref, {':projectid': projectId}, {});
  }

  deleteProject(projectId: ObjectId) {
    return ApiService.ENDPOINTS.deleteProject.run(this.ref, {':projectid': projectId}, {});
  }

  getDateRange() {
    return ApiService.ENDPOINTS.getDateRange.run(this.ref, {}, {});
  }

  listRows(t0: Date, t1: Date) {
    return ApiService.ENDPOINTS.listRows.run(this.ref, {'start':DateUtils.formatRFC3339(t0), 'end':DateUtils.formatRFC3339(t1), 'all': true}, {});
  }

  listSheets(t0: Date, t1: Date) {
    return ApiService.ENDPOINTS.listSheets.run(this.ref, {'start':DateUtils.formatRFC3339(t0), 'end':DateUtils.formatRFC3339(t1), 'all': true}, {});
  }

  getOverview() {
    return ApiService.ENDPOINTS.getOverview.run(this.ref, {}, {});
  }

  getAllElements() {
    return ApiService.ENDPOINTS.getAllElements.run(this.ref, {}, {});
  }

  createUser(username: string, pw: string, isadmin: boolean, table: ObjectId|null) {
    return ApiService.ENDPOINTS.createUser.run(this.ref, {}, {
      'username': username,
      'password': pw,
      'isAdmin': isadmin,
      'tables': (table === null) ? [] : [table],
    });
  }

  updateUserData(uid: ObjectId, username: string, isadmin: boolean, table: ObjectId|null) {
    return ApiService.ENDPOINTS.updateUser.run(this.ref, {':uid': uid}, {
      'username': username,
      'isAdmin': isadmin,
      'tables': table === null ? [] : [table],
    });
  }

  updateUserPass(uid: ObjectId, pw: string) {
    return ApiService.ENDPOINTS.updateUser.run(this.ref, {':uid': uid}, {
      'password': pw,
    });
  }

  createExcel(tabledata: CreateExcelData) {
    return ApiService.ENDPOINTS.createExcel.run(this.ref, {}, { 'rows': tabledata.rows });
  }

  async refreshToken() {
    try {
      const data = await this.refreshAdminToken();
      if (data.token) {
        this.auth.setAuth(data.token);
      } else {
        this.notification.error('Fehler', 'Token konnte nicht aktualisiert werden (empty)');
      }
    } catch (err) {
      showAPIError(this.notification, 'Token konnte nicht aktualisiert werden', err)
    }
  }
}
