import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ColumnDefinition, ColumnType} from "../../interfaces/table";
import {CalcRow, CreateRow, UpdateRow} from "../../interfaces/sheet";
import {Employee} from "../../interfaces/employee";
import {Project} from "../../interfaces/project";

@Component({
  selector: 'epl-cell-input',
  templateUrl: './cell-input.component.html',
  styleUrls: ['./cell-input.component.scss']
})
export class CellInputComponent {

  @Input()  columnType: ColumnType|null = null;
  @Input()  index: number = -1;
  @Input()  row: CreateRow|UpdateRow|null = null;
  @Input()  value: string = '';
  @Input()  column: ColumnDefinition|null = null;
  @Input()  employees: Employee[] = [];
  @Input()  projects: Project[] = [];

  @Output() onChanged: EventEmitter<string> = new EventEmitter();

  formatterNumber  = (value: number|null): string => (value === null) ? '' : `${value}`;

  onCellUpdated(newval: string) {
    this.onChanged.emit(newval);
  }
}
