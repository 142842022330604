
<epl-no-frame>

    <main>

        <h1 style="font-weight: bold">eProduktionsliste</h1>

        <nz-alert *ngIf="errorText != ''" nzType="error" [nzMessage]="errorText"></nz-alert>

        <input type="email" [(ngModel)]="email" nz-input placeholder="Email" (keyup.enter)="this.inputPassword.focus()" />

        <input type="password" #inputPassword [(ngModel)]="password" nz-input placeholder="Password" (keyup.enter)="login()" />

        <button nz-button class="login-form-button login-form-margin" style="margin-top: 16px" [nzType]="'primary'" [nzLoading]="loginLoading" (click)="login()">Log in</button>

    </main>


</epl-no-frame>

