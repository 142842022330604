import { AuthenticationService } from '../services/authentication.service';
import {inject} from '@angular/core';
import {Router} from '@angular/router';

export const adminAuthGuard = () => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);

  if (!authService.isAuthenticated() || !authService.isAdminUser()) {
    return router.parseUrl('/login');
  }

  return true;
};
