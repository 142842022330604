import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'trustScript'
})
export class TrustScriptPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer){}

  transform(value: string, ...args: unknown[]) {
    return this.sanitizer.bypassSecurityTrustScript(value);
  }
}
