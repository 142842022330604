import {Component, OnInit} from '@angular/core';
import {Table} from "../../interfaces/table";
import {Sheet} from "../../interfaces/sheet";
import {ApiService} from "../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DateUtils} from "../../utils/date";
import {RFC3339} from "../../interfaces/datetime";
import {showAPIError} from "../../utils/api";
import {ObjectId} from "../../interfaces/utils";
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: 'epl-table-history',
  templateUrl: './table-history.component.html',
  styleUrls: ['./table-history.component.scss']
})
export class TableHistoryComponent implements OnInit{
  table: Table|null = null;
  sheets: Sheet[]= [];
  tableId: ObjectId | null = null;
  constructor(private apiService: ApiService,
              private router: Router,
              private notification: NzNotificationService,
              private activeRoute: ActivatedRoute){

  }

   ngOnInit() {
    this.activeRoute.paramMap.subscribe(async params => {
      this.tableId = (params.get('id') ?? null) as (ObjectId|null);

      if(null != this.tableId) {
        try {
          this.table  = await this.apiService.getTable(this.tableId);
          this.sheets = (await this.apiService.getTableHistory(this.tableId)).sheets;
        } catch (err) {
          showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
        }
      }
    });
  }
}
