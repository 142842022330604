<epl-default-frame>
  <h1>Tabellenverwaltung</h1>

  <a nz-button nzType="primary" routerLink="/addTable" style="margin-bottom: 1rem">Neue Tabelle anlegen</a>

  <nz-table #basicTable [nzData]="tables" [nzFrontPagination]="false">
    <thead>
    <tr>
      <th>Name</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of tables">
      <td>{{data.header}}</td>
      <td>
        <a      nz-button style="margin-left: 0.5rem" [routerLink]="['/table-history', data.id]"><span nz-icon nzType="eye"         ></span> Blätter Anzeigen</a>
        <button nz-button style="margin-left: 0.5rem" (click)="openLatestSheet(data)"           ><span nz-icon nzType="fast-forward"></span> Aktuelles Blatt</button>
        <a      nz-button style="margin-left: 0.5rem" [routerLink]="['/table', data.id]"        ><span nz-icon nzType="edit"        ></span> Bearbeiten</a>
        <button nz-button style="margin-left: 0.5rem" nzDanger nz-popconfirm nzPopconfirmTitle="Tabelle wirklich löschen?" (nzOnConfirm)="deleteTable(data)"><span nz-icon nzType="delete"></span> Löschen</button>
      </td>
    </tr>
    </tbody>
  </nz-table>
</epl-default-frame>
