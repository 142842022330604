import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {environment} from "../../environments/environment";
import {JwtHelperService} from '@auth0/angular-jwt';
import {CookieService} from 'ngx-cookie-service';
import { User } from '../interfaces/user';
import {ObjectId} from "../interfaces/utils";

interface JWTClaims {
  id: ObjectId;
  username: string;
  isAdmin: boolean;
  v: number;
  exp: number;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  claimCache: [string, JWTClaims]|undefined = undefined;

  selfCache: {[_:string]: User} = {};

  constructor(private router: Router,
              private notification: NzNotificationService,
              private cookie: CookieService) {}

  isAuthenticated(): boolean {
    return ((this.getClaimsFromToken()?.exp ?? 0) * 1000) > new Date().getTime();
  }

  setAuth(accessToken: string): void {
    this.cookie.set(environment.cookieKey, accessToken, new JwtHelperService().decodeToken(accessToken).exp * 1000, '/', environment.cookieBaseUrl);
    this.claimCache = undefined;
  }

  clearAuth(): void {
    this.cookie.delete(environment.cookieKey, '/', environment.cookieBaseUrl);
    this.claimCache = undefined;
    this.selfCache = {};
  }

  getToken(): string {
    return this.cookie.get(environment.cookieKey);
  }

  getClaimsFromToken(): JWTClaims | null {
    const tok = this.getToken();
    if (this.claimCache !== undefined && tok === this.claimCache[0]) return this.claimCache[1];

    const claims = new JwtHelperService().decodeToken<JWTClaims>(tok);

    if (claims !== null) this.claimCache = [tok, claims];

    return claims;
  }

  getSelfID(): ObjectId|null {
    const claims = this.getClaimsFromToken();
    if (!claims) return null;
    return claims.id;
  }

  getSelf(): User|null {
    let id = this.getSelfID();
    if (id === null) return null;
    let v = this.selfCache[id];
    if (v === undefined) return null;
    return v;
  }

  setSelf(data: User) {
    this.selfCache[data.userId] = data;
  }

  isAdminUser() {
    const claims = this.getClaimsFromToken();
    if (!claims) return null;
    return claims.isAdmin;
  }
}
