import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {Router} from "@angular/router";
import {SheetOverview} from "../../interfaces/sheetOverview";
import {showAPIError} from "../../utils/api";
import {getAreaFromRow, getRowIsProject, getVolumeFromRow, Row} from "../../interfaces/sheet";
import {Element, Project} from "../../interfaces/project";
import {ColumnType, Table} from "../../interfaces/table";

interface ExtSheetOverview extends SheetOverview {
  percArea: number|null;
  percVolume: number|null;
}

@Component({
  selector: 'epl-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  data: ExtSheetOverview[] = [];

  constructor(private apiservice: ApiService,
              private notification: NzNotificationService,
              private router: Router) {

  }

  async ngOnInit() {
    try {
      this.data = await Promise.all((await this.apiservice.getOverview()).entries.map(p => this.calculate(p)));
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
    }
  }

  private async calculate(soverview: SheetOverview): Promise<ExtSheetOverview> {

    if (soverview.activeProject === null) return ({...soverview, percArea: null, percVolume: null});

    const elements = (await this.apiservice.getElementsByProject(soverview.activeProject!.projectId)).elements

    const areaDone = soverview.rows
        .filter(p => getRowIsProject(p, soverview.table, soverview.activeProject!))
        .map(p => getAreaFromRow(soverview.table, p, soverview.activeProject!, elements))
        .filter(p => p !== null)
        .map(p => p!)
        .reduce((a,b)=>a+b, 0);

    const volumeDone = soverview.rows
        .filter(p => getRowIsProject(p, soverview.table, soverview.activeProject!))
        .map(p => getVolumeFromRow(soverview.table, p, soverview.activeProject!, elements))
        .filter(p => p !== null)
        .map(p => p!)
        .reduce((a,b)=>a+b, 0);

    return  {
      ...soverview,
      percArea:   (areaDone   / soverview.activeProject.areaSum)*100,
      percVolume: (volumeDone / soverview.activeProject.volumeSum)*100,
    };
  }
}
