<epl-default-frame>
  <h1>Alle Blätter der Tabelle "{{table?.header ?? '???'}}"</h1>
  <nz-table [nzData]="sheets" [nzFrontPagination]="false" >
    <thead>
      <tr>
        <th>Datum</th>
        <th>Anzahl Einträge</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of sheets">
        <td>{{ item.created | date:'dd.MM.yyyy' }}</td>
        <td>{{ item.rowCount }}</td>
        <td>
          <a nz-button [routerLink]="['/', 'edit-sheet', item.sheetId]"  style="margin-left: 0.5rem" ><span nz-icon nzType="eye"></span> Bearbeiten/Anzeigen</a>
        </td>
      </tr>
    </tbody>
  </nz-table>
</epl-default-frame>
