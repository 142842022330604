import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatFloat'
})
export class FormatFloatPipe implements PipeTransform {

  transform(value: number): string {
    return value.toString().replace('.', ',');
  }

}
