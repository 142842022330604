<epl-default-frame>
  <h1>Mitarbeiterverwaltung</h1>

<!--  <a nz-button href="editEmployee">Neuen Mitarbeiter anlegen</a>-->
  <nz-table #employeeTable [nzData]="employeeData" [nzFrontPagination]="false">
    <thead>
    <tr>
      <th>Vorname</th>
      <th>Name</th>
      <th>Kürzel</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td> <input nz-input nzSize="small" placeholder="Vorname" [(ngModel)]="newEmployee.firstname"/></td>
      <td> <input nz-input nzSize="small" placeholder="Nachname"  [(ngModel)]="newEmployee.lastname"/></td>
      <td> <input nz-input nzSize="small" placeholder="Kürzel"  [(ngModel)]="newEmployee.abbreviation"/></td>
      <td> <button nz-button nzType="primary" (click)="addEmployee()"><span nz-icon nzType="plus"></span>Hinzufügen</button></td>
    </tr>
    <tr *ngFor="let data of employeeData">
      <ng-container *ngIf="data.employeeId != editedEmployee?.employeeId; else elseBlock">
      <td>{{data.firstname}}</td>
      <td>{{data.lastname}}</td>
      <td>{{data.abbreviation}}</td>
      <td>
        <button nz-button (click)="editEmployee(data)"   style="margin-left: 0.5rem"><span nz-icon nzType="edit"></span> Bearbeiten</button>
        <button nz-button  nz-popconfirm nzPopconfirmTitle="Mitarbeiter wirklich löschen?" (nzOnConfirm)="deleteEmployee(data)" style="margin-left: 0.5rem"  nzDanger><span nz-icon nzType="delete"></span>Löschen</button>
      </td>
      </ng-container>
      <ng-template #elseBlock>
        <td> <input nz-input nzSize="small" placeholder="Vorname"   [(ngModel)]="editedEmployee!.firstname"/></td>
        <td> <input nz-input nzSize="small" placeholder="Nachname"  [(ngModel)]="editedEmployee!.lastname"/></td>
        <td> <input nz-input nzSize="small" placeholder="Kürzel"    [(ngModel)]="editedEmployee!.abbreviation"/></td>
        <td>
          <button nz-button (click)="updateEmployee()" style="margin-left: 0.5rem" nzType="primary"          ><span nz-icon nzType="save"></span> Speichern</button>
          <button nz-button (click)="cancelEdit()"     style="margin-left: 0.5rem" nzType="primary" nzDanger ><span nz-icon nzType="close"></span> Abbrechen</button>
        </td>
      </ng-template>
    </tr>

    </tbody>
  </nz-table>
</epl-default-frame>
