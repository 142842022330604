import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ApiService} from "../../services/api.service";
import {filetoBase64} from "../../utils/base64";
import {showAPIError} from "../../utils/api";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {Project} from "../../interfaces/project";
import {CursorToken, ObjectId} from "../../interfaces/utils";
import {Element} from "../../interfaces/project";

@Component({
  selector: 'epl-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit{

  showImportModal: boolean = false;
  importFile: File|null = null;
  importPreviewLoading: boolean = false;
  importLoading: boolean = false;
  importPreviewElements: Element[]|null = null;
  importProjectname: string = '';

  showElementsProject: Project|null = null;
  showElementsData: Element[] = [];
  showElementsLoading: boolean = false;

  loading: boolean = true;
  isLoadingMore: boolean = false;
  projects: Project[] = [];

  constructor(private http: HttpClient,
              private apiservice: ApiService,
              private notification: NzNotificationService) {

  }

  async ngOnInit() {
    await this.loadProjects();
  }

  async loadProjects() {
    try {
      this.loading = true;
      this.projects = (await this.apiservice.getProjects()).projects;
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
    } finally {
      this.loading = false;
    }
  }

  async onImportFileSelected(event: Event) {
    this.importFile = (event.target as any).files[0];
    this.importPreviewElements = null;

    if (this.importFile === null) return;

    this.importProjectname = this.importFile.name.replace(/\.xlsx?/i, "");

    try {
      this.importPreviewLoading = true;

      const encodedFile =  await filetoBase64(this.importFile);
      const resp = await this.apiservice.createProject(encodedFile, this.importFile.name, this.importProjectname, true);

      this.importPreviewElements = resp.elements;
    } catch (err) {
      showAPIError(this.notification, 'Datei konnten nicht analysiert werden', err);
    } finally {
      this.importPreviewLoading = false;
    }
  }

  async doImport() {
    if (this.importFile === null) return;
    if (this.importProjectname === '') return;

    try {
      this.importLoading = true;

      const encodedFile =  await filetoBase64(this.importFile);
      const resp = await this.apiservice.createProject(encodedFile, this.importFile.name, this.importProjectname, false);

      this.projects = [resp.project, ...this.projects];

      this.showImportModal = false;

    } catch (err) {
      showAPIError(this.notification, 'Datei konnten nicht analysiert werden', err);
    } finally {
      this.importLoading = false;
    }
  }

  async loadElementsForShowElementsModal() {
    if (this.showElementsProject === null) return;

    try {
      this.showElementsLoading = true;
      const resp = await this.apiservice.getElementsByProject(this.showElementsProject.projectId);
      this.showElementsData = resp.elements;
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
    } finally {
      this.showElementsLoading = false;
    }
  }

  async deleteProject(data: Project) {
    try {
      await this.apiservice.deleteProject(data.projectId);
      this.projects = this.projects.filter(p => p.projectId !== data.projectId);
    } catch (err) {
      showAPIError(this.notification, 'Projekt konnten nicht gelöscht werden', err);
    }
  }

  openImportModal() {
    this.importFile = null;
    this.importPreviewLoading = false;
    this.importLoading = false;
    this.importPreviewElements = null;
    this.importProjectname = '';

    this.showImportModal = true;
  }

  viewProject(v: Project) {
    this.showElementsLoading = true;
    this.showElementsData = [];

    this.showElementsProject = v;

    this.loadElementsForShowElementsModal().then(() => {});
  }
}
