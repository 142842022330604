export function b64toBlob(b64Data: string, contentType: string): Blob {
  contentType = contentType || '';
  const sliceSize = 512;
  b64Data = b64Data.replace(/^[^,]+,/, '');
  b64Data = b64Data.replace(/\s/g, '');
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, {type: contentType});
}

export function filetoBase64(file: File) {
  // https://stackoverflow.com/a/57272491/1761622
  return filetoDataURL(file).then(p => p.split(",").pop()!)
}

export function filetoDataURL(file: File): Promise<string> {
  // https://stackoverflow.com/a/57272491/1761622
  return new Promise((resolve, reject) => {

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  })
}
