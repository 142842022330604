<epl-default-frame>
    <h1 *ngIf="mode === 'week'"  >Wochenübersicht</h1>
    <h1 *ngIf="mode === 'month'" >Monatsübersicht</h1>
    <h1 *ngIf="mode === 'year'"  >Jahresübersicht</h1>

    <div class="weeklist">

        <ng-container *ngIf="mode === 'week'">
            <a *ngFor="let e of weeks; let i = index" nz-button nzSize="large" nzType="default" [routerLink]="e.url">{{e.name}}</a>
        </ng-container>

        <ng-container *ngIf="mode === 'month'">
            <a *ngFor="let e of months; let i = index" nz-button nzSize="large" nzType="default" [routerLink]="e.url">{{e.name}}</a>
        </ng-container>

        <ng-container *ngIf="mode === 'year'">
            <a *ngFor="let e of years; let i = index" nz-button nzSize="large" nzType="default" [routerLink]="e.url">{{e.name}}</a>
        </ng-container>

        <div></div>

    </div>

</epl-default-frame>
