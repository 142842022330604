import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {ColumnDefinition, ColumnType, ColumnTypes, Table} from "../../interfaces/table";
import {ActivatedRoute, Router} from "@angular/router";
import {ObjectId} from "../../interfaces/utils";
import {showAPIError} from "../../utils/api";
import {NzNotificationService} from "ng-zorro-antd/notification";

function newColumn(): ColumnDefinition {
    return ({
        columnId: ('' as ObjectId),
        header: '',
        width: '',
        type: ColumnType.Text,
        created: null,
        selectValues: [],
        allowEmpty: false,
        autoFillFromPrevious: false,
        mustBeChecked: false,
    });
}

@Component({
  selector: 'epl-add-table',
  templateUrl: './add-table.component.html',
  styleUrls: ['./add-table.component.scss']
})
export class AddTableComponent implements OnInit {

  options: ColumnType[] = ColumnTypes;

  columns: ColumnDefinition[] = [newColumn()];

  tableName: string = '';

  enableHours: boolean = true;
  enableElementSums: boolean = true;
  enableSummaryRows: boolean = true;

  tableId: ObjectId | null = null;

  loading: boolean = true;

  constructor(private apiService: ApiService,
              private route: ActivatedRoute,
              private notification: NzNotificationService,
              private router: Router)  {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(async params => {
      this.tableId = params.get('id') as (ObjectId|null);

      if(this.tableId) {

        try {
          const table = await this.apiService.getTable(this.tableId);

          this.tableName         = table.header;
          this.columns           = table.columns;
          this.enableHours       = table.enableHours;
          this.enableElementSums = table.enableElementSums;
          this.enableSummaryRows = table.enableSummaryRows;
        } catch (err) {
          showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
        } finally {
            this.loading = false;
        }

      } else {
          this.loading = false;
      }
    });
  }

  async submitTable() {
    try {

      if(this.tableId === null) {
        //create table in db

        await this.apiService.createTable(this.tableName, this.columns, this.enableHours, this.enableElementSums, this.enableSummaryRows);
        await this.router.navigate(['/tables']);

      } else {

        await this.apiService.updateTable(this.tableId, this.tableName, this.columns, this.enableHours, this.enableElementSums, this.enableSummaryRows);
        await this.router.navigate(['/tables']);

      }

    } catch (err) {
      showAPIError(this.notification, 'Tabelle konnte nicht erstellt werden', err);
    }
 }

  moveColumnUp(idx: number, data: ColumnDefinition) {
      if (idx <= 0) return;
      this.columns = [ ...this.columns.slice(0,idx-1), this.columns[idx], this.columns[idx-1], ...this.columns.slice(idx+1) ];
  }

  moveColumnDown(idx: number, data: ColumnDefinition) {
      if (idx >= this.columns.length-1) return;
      this.columns = [ ...this.columns.slice(0,idx), this.columns[idx+1], this.columns[idx], ...this.columns.slice(idx+2) ];
  }

  deleteColumn(idx: number, data: ColumnDefinition) {
    this.columns = this.columns.filter(p => (p !== data))
  }

  addColumnAbove(idx: number, data: ColumnDefinition) {
      this.columns = [ ...this.columns.slice(0,idx), newColumn(), ...this.columns.slice(idx) ];
  }

  addColumnBelow(idx: number, data: ColumnDefinition) {
      this.columns = [ ...this.columns.slice(0,idx+1), newColumn(), ...this.columns.slice(idx+1) ];
  }

  addSelectValue(data: ColumnDefinition, selectValueInput: HTMLInputElement) {
    if (data.selectValues.find(p => p === selectValueInput.value)) return;
      data.selectValues = [...data.selectValues, selectValueInput.value];
  }

  deleteSelectValue(data: ColumnDefinition, v: string) {
      data.selectValues = data.selectValues.filter(dsv => dsv != v);
  }
}
