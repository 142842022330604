import {Component, OnDestroy, OnInit} from '@angular/core';
import {CalcRow, CalculatedRowData, Cell, CreateRow, Row, Sheet, UpdateRow} from "../../interfaces/sheet";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../services/api.service";
import {ColumnDefinition, ColumnType, Table} from "../../interfaces/table";
import {Employee} from "../../interfaces/employee";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {showAPIError} from "../../utils/api";
import {ObjectId} from "../../interfaces/utils";
import {User} from "../../interfaces/user";
import {AuthenticationService} from "../../services/authentication.service";
import {Element, Project} from "../../interfaces/project";
import {deepclone} from "../../utils/angular";
import {DateUtils} from "../../utils/date";
import {CreateExcelData} from "../../interfaces/excel";
import {environment} from "../../../environments/environment";

interface SummaryRow {
  project: Project;

  percentageSum: number;

  areaSum: number;
  volumeSum: number;

  areaPerc: number;
  volumePerc: number;

  areaTotal: number;
  volumeTotal: number;
}

@Component({
  selector: 'epl-editsheet',
  templateUrl: './editsheet.component.html',
  styleUrls: ['./editsheet.component.scss']
})
export class EditsheetComponent implements OnInit, OnDestroy {
  sheet: Sheet|null = null;
  sheetId: ObjectId | null = null;

  loading: boolean = true;

  sheetDirty: boolean = false;

  rows: CalcRow[] = [];
  table: Table|null = null;

  editRow: UpdateRow|null = null;

  summaries: SummaryRow[] = [];

  employees: Employee[] = [];
  projects: Project[] = [];

  _elementsForProject: Map<ObjectId, Element[]> = new Map<ObjectId, Element[]>();

  projectColumnId:    ObjectId|null = null;
  elementColumnId:    ObjectId|null = null;
  percentageColumnId: ObjectId|null = null;
  areaColumnId:       ObjectId|null = null;
  volumeColumnId:     ObjectId|null = null;
  employeeColumnId:   ObjectId|null = null;
  identColumnId:      ObjectId|null = null;

  newRow: CreateRow = this.createNewRowObjectWithoutCalcData(null);

  formatterNumber  = (value: number|null): string => (value === null) ? '' : `${value}`;

  user: User|null = null;

  isPastDate:            boolean = false;
  pastDateAdminOverride: boolean = false;

  bgTimer: number|null = null;

  tableMinDate: Date|null = null;
  tableMaxDate: Date|null = null;

  allowNormalUserEdit: boolean = true; // always true for now, later perhaps table/global setting

  dateNotInRange = (d: Date) => this.tableMinDate === null || this.tableMaxDate === null || d.getTime() < this.tableMinDate.getTime() || d.getTime() > this.tableMaxDate.getTime();

  constructor(private activeRoute: ActivatedRoute,
              private notification: NzNotificationService,
              private authservice: AuthenticationService,
              private apiService: ApiService,
              private router: Router) {

  }

  async ngOnInit() {
    this.activeRoute.paramMap.subscribe(async params => {

      this.loading = true;

      try {
        this.sheetId = params.get('id') as (ObjectId|null);
        this.employees = (await this.apiService.getEmployees()).employees;
        this.projects  = (await this.apiService.getProjects()).projects;
        this.sheet     = (await this.apiService.getSheet(this.sheetId!));

        const allsheets = (await this.apiService.getTableHistory(this.sheet.tableId)).sheets;

        const sheetdate = DateUtils.parseRFC3339(this.sheet.created);

        this.isPastDate = !DateUtils.isSameDay(sheetdate, new Date());

        this.tableMinDate = DateUtils.min(allsheets.map(p => DateUtils.parseRFC3339(p.created))) ?? sheetdate;
        this.tableMaxDate = DateUtils.max(allsheets.map(p => DateUtils.parseRFC3339(p.created))) ?? sheetdate;

        await this.loadTable();

      } catch (err) {
        showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
      } finally {
        this.loading = false;
      }
    });

    this.user = this.authservice.getSelf();
    if (this.user === null) {
      try {
        const data = await this.apiService.getUser(this.authservice.getSelfID()!);
        this.authservice.setSelf(data);
        this.user = data;
      } catch (err) {
        showAPIError(this.notification, 'Benutzer konnte nicht geladen werden', err)
      }
    }

    this.bgTimer = setInterval(() => this.onTimer(), 1000 * 60);

  }

  ngOnDestroy() {
    if (this.bgTimer) { clearInterval(this.bgTimer); this.bgTimer = null; }
  }

  onTimer() {
    if (this.sheet) {
      this.isPastDate = !DateUtils.isSameDay(DateUtils.parseRFC3339(this.sheet.created), new Date());
    }
  }

  private async loadTable()  {
    if (this.sheet === null) {
      this.table = null;
      return;
    }

    try {
      const table = await this.apiService.getTable(this.sheet.tableId);
      const rows = (await this.apiService.getRows(this.sheet?.sheetId!)).rows.map(p => ({...p, calculated: this.emptyCalculatedRowData()}));

      this.findColumns(table);

      if(this.projectColumnId !== null) {
        for(let i = 0; i < rows.length; i++) {
          const pid = rows[i].cells[this.projectColumnId].value;

          if (!this.projects.find(p => p.projectId === pid)) {
            try {
              console.log('Force-Query deleted project', pid);
              const fqp = await this.apiService.getProject(pid as ObjectId);
              this.projects = [...this.projects, fqp];
            } catch (err) {
              console.error('Failed to Force-Query deleted project', pid, err); // non-fatal, just continue
            }
          }

          if (pid) await this.collectElementsForProject(pid as ObjectId);
        }
      }

      if(this.employeeColumnId !== null) {
        for(let i = 0; i < rows.length; i++) {
          const eid = rows[i].cells[this.employeeColumnId].value;

          if (!this.employees.find(p => p.employeeId === eid)) {
            try {
              console.log('Force-Query deleted employee', eid);
              const fqe = await this.apiService.getEmployee(eid as ObjectId);
              this.employees = [...this.employees, fqe];
            } catch (err) {
              console.error('Failed to Force-Query deleted employee', eid, err); // non-fatal, just continue
            }
          }
        }
      }

      for (let i = 0; i < rows.length; i++) {
        rows[i] = await this.updateCalculatedRowData(rows[i]);
      }

      this.table = table
      this.rows = rows;
      this.newRow = await this.createNewRowObject(this.rows?.at(-1) ?? null);

      await this.updateSummaries();

    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
    }
  }

  private createNewRowObjectWithoutCalcData(previous: Row|null): CreateRow {
    if (this.table === null) return {'sheetId': ('' as ObjectId), 'cells': {}, 'calculated': this.emptyCalculatedRowData()};
    if (this.sheet === null) return {'sheetId': ('' as ObjectId), 'cells': {}, 'calculated': this.emptyCalculatedRowData()};

    let newRow: CreateRow = {'sheetId': this.sheet.sheetId!, 'cells': {}, 'calculated': this.emptyCalculatedRowData()};
    for(let i = 0; i<this.table.columns.length; i++) {

      newRow.cells[this.table.columns[i].columnId] = ({ value: '' });

      if(this.table.columns[i].type == ColumnType.Check){
        newRow.cells[this.table.columns[i].columnId].value = "false";
      }

      if (this.table.columns[i].autoFillFromPrevious && previous !== null && previous.cells[this.table.columns[i].columnId] !== undefined) {
        newRow.cells[this.table.columns[i].columnId].value = previous.cells[this.table.columns[i].columnId].value;
      }
    }

    return newRow;
  }

  private async createNewRowObject(previous: Row|null): Promise<CreateRow> {
    let newRow = this.createNewRowObjectWithoutCalcData(previous);

    newRow = await this.updateCalculatedRowData(newRow);

    return newRow;
  }

  async submitRow() {
    if (this.table === null) return;
    if (this.sheet === null) return;

    if (!DateUtils.isSameDay(DateUtils.parseRFC3339(this.sheet.created), new Date())) {
      if (this.user === null || !this.user.isAdmin) {
        this.notification.error('Fehler', `Dies ist nicht das aktuelle (heutige) Blatt und kann nicht mehr bearbeitet werden`);
        return;
      }
    }

    for (const column of this.table.columns) {

      if (column.type === 'rownumber') {
        this.newRow.cells[column.columnId].value = `${this.rows.length+1}`;
      }

      const value = this.newRow.cells[column.columnId].value;

      if (column.type === 'check' && column.mustBeChecked) {
        if (value !== 'true') {
          this.notification.error('Fehler', `Dert Wert "${column.header}" muss bestätigt werden`);
          return;
        }
      }

      if (!column.allowEmpty) {
        if (value === '' || value === undefined) {
          this.notification.error('Fehler', `Dert Wert "${column.header}" muss ausgefüllt werden`);
          return;
        }
      }

      this.newRow.cells[column.columnId].value = `${this.newRow.cells[column.columnId].value}`; // convert all to string (table-cols) (and set missing to empty-str)
    }

    for (const cid of Object.keys(this.newRow.cells).map(p=>p as ObjectId)) this.newRow.cells[cid].value = `${this.newRow.cells[cid].value}`; // convert all to string (obj-cols)

    try {

      let rawdata = await this.apiService.addRow(this.newRow);
      let data = {...rawdata, 'calculated': this.emptyCalculatedRowData()} as CalcRow;

      data = await this.updateCalculatedRowData(data);

      this.newRow = await this.createNewRowObject(data);

      this.rows = [...this.rows, data];

    } catch (err) {
      showAPIError(this.notification, 'Zeile konnten nicht angelegt werden', err);
    }
  }

  getCellDisplayValue(col: ColumnDefinition, row: CalcRow, idx: number) {
    //find collumn type for cell
    let valueObject = row.cells[col.columnId];
    let columnType = col.type;

    if(columnType == ColumnType.Text)           return valueObject.value;
    if(columnType == ColumnType.Number)         return valueObject.value;
    if(columnType == ColumnType.Check)          return valueObject.value;
    if(columnType == ColumnType.Date)           return (new Date(valueObject.value)).toLocaleDateString();
    if(columnType == ColumnType.Select)         return valueObject.value;
    if(columnType == ColumnType.Percentage)     return valueObject.value + " %";
    if(columnType == ColumnType.Project)        return row.calculated.project?.name ?? '???';
    if(columnType == ColumnType.Element)        return row.calculated.element?.name ?? '???';
    if(columnType == ColumnType.ElementArea)    return parseFloat(valueObject.value).toFixed(2);
    if(columnType == ColumnType.ElementVolume)  return parseFloat(valueObject.value).toFixed(2);
    if(columnType == ColumnType.ElementIdent)   return valueObject.value;
    if(columnType == ColumnType.Employee)       return this.formatEmployeeValue(valueObject);
    if(columnType == ColumnType.RowNumber)      return `${idx+1}`;

    return valueObject.value;
  }

  formatEmployeeValue(valueObject: Cell) {
    let currentEmployee = this.employees?.find(employee => employee.employeeId == valueObject.value);
    let currentEmployeeString = "";
    if (currentEmployee != undefined) {
      currentEmployeeString = currentEmployee?.firstname + ' ' + currentEmployee?.lastname;
    }
    return currentEmployeeString;
  }

  findColumns(table: &Table){

    this.projectColumnId    = null;
    this.elementColumnId    = null;
    this.percentageColumnId = null;
    this.areaColumnId       = null;
    this.volumeColumnId     = null;
    this.employeeColumnId   = null;
    this.identColumnId      = null;

    for(let i = 0; i < table.columns.length; i++)
    {
      if(table.columns[i].type == ColumnType.Project)       this.projectColumnId    = table.columns[i].columnId;
      if(table.columns[i].type == ColumnType.Element)       this.elementColumnId    = table.columns[i].columnId;
      if(table.columns[i].type == ColumnType.Percentage)    this.percentageColumnId = table.columns[i].columnId;
      if(table.columns[i].type == ColumnType.ElementArea)   this.areaColumnId       = table.columns[i].columnId;
      if(table.columns[i].type == ColumnType.ElementVolume) this.volumeColumnId     = table.columns[i].columnId;
      if(table.columns[i].type == ColumnType.Employee)      this.employeeColumnId   = table.columns[i].columnId;
      if(table.columns[i].type == ColumnType.ElementIdent)  this.identColumnId      = table.columns[i].columnId;
    }

    console.log("determined special column-ids", {
      'projectColumnId':    this.projectColumnId,
      'elementColumnId':    this.elementColumnId,
      'percentageColumnId': this.percentageColumnId,
      'areaColumnId':       this.areaColumnId,
      'volumeColumnId':     this.volumeColumnId,
      'employeeColumnId':   this.employeeColumnId,
      'identColumnId':      this.identColumnId,
    })
  }

  async updateSheet() {

    if (this.sheet === null) return;

    try {

      await this.apiService.updateSheetWorkers(this.sheet.sheetId, this.sheet.numberWorkers, this.sheet.hoursOffice, this.sheet.hoursFactory, this.sheet.hoursDowel, this.sheet.hoursOther);
      this.sheetDirty = false;

    } catch (err) {
      showAPIError(this.notification, 'Blatt konnten nicht aktualisiert werden', err);
    }
  }

  async collectElementsForProject(projectId: ObjectId|string): Promise<Element[]> {

    let v = this._elementsForProject.get(projectId as ObjectId);
    if(v) return v;

    console.log("collecting elements for project", projectId);

    const resp = await this.apiService.getElementsByProject(projectId as ObjectId);

    this._elementsForProject.set(projectId as ObjectId, resp.elements);
    return resp.elements;
  }

  private emptyCalculatedRowData(): CalculatedRowData {
    return {
      element:         null,
      area:            null,
      volume:          null,
      project:         null,
      projectElements: [],
    };
  }

  private async updateCalculatedRowData<T extends CalcRow|CreateRow|UpdateRow>(row: T): Promise<T> {

    row.calculated.project         = null;
    row.calculated.element         = null;
    row.calculated.area            = null;
    row.calculated.volume          = null;
    row.calculated.projectElements = [];

    if (this.projectColumnId) row.calculated.project = this.projects.find(p => p.projectId === row.cells[this.projectColumnId??('' as ObjectId)]?.value) ?? null;

    if (row.calculated.project !== null) {

      const elements = await this.collectElementsForProject(row.calculated.project.projectId)

      if (this.elementColumnId) {
        const elemId = row.cells[this.elementColumnId]?.value

        row.calculated.projectElements = elements;

        if (elemId) {
          row.calculated.element = elements.find(p => p.elementId === elemId) ?? null

          if (row.calculated.element) {
            let perc = parseFloat(row.cells[this.percentageColumnId??('' as ObjectId)]?.value ?? '100.0');
            if (!!perc || (perc === 0.0)) {
              row.calculated.area   = (perc/100.0) * row.calculated.element.area;
              row.calculated.volume = (perc/100.0) * row.calculated.element.volume;
            }
            if (this.identColumnId !== null) {
              if (row.cells[this.identColumnId]) row.cells[this.identColumnId].value   =          row.calculated.element.ident;
              else                               row.cells[this.identColumnId]         = { value: row.calculated.element.ident };
            }
          } else {
            if (row.cells[this.elementColumnId]) row.cells[this.elementColumnId].value   =          '';
            else                                 row.cells[this.elementColumnId]         = { value: '' };
          }
        }
      }
    }

    if (this.areaColumnId !== null)
    {
      if (row.calculated.area !== null)
      {
        if (row.cells[this.areaColumnId]) row.cells[this.areaColumnId].value   =          row.calculated.area.toString();
        else                              row.cells[this.areaColumnId]         = { value: row.calculated.area.toString() };
      }
      else
      {
        if (row.cells[this.areaColumnId]) row.cells[this.areaColumnId].value   =          '';
        else                              row.cells[this.areaColumnId]         = { value: '' };
      }
    }

    if (this.volumeColumnId !== null)
    {
      if (row.calculated.volume !== null)
      {
        if (row.cells[this.volumeColumnId]) row.cells[this.volumeColumnId].value   =          row.calculated.volume.toString();
        else                                row.cells[this.volumeColumnId]         = { value: row.calculated.volume.toString() };
      }
      else
      {
        if (row.cells[this.volumeColumnId]) row.cells[this.volumeColumnId].value   =          '';
        else                                row.cells[this.volumeColumnId]         = { value: '' };
      }
    }

    return row;
  }

  async onCellUpdated(row: CreateRow, col: ColumnDefinition) {
    this.newRow = await this.updateCalculatedRowData(this.newRow);
    await this.updateSummaries();
  }

  async onEditRowCellUpdated(col: ColumnDefinition) {
    if (!this.editRow) return;

    this.editRow = await this.updateCalculatedRowData(this.editRow);

    await this.updateSummaries();
  }

  async updateSummaries() {
    let summaries: SummaryRow[] = [];

    let allrows = [
        ...this.rows.map(p => (p.rowId && this.editRow && p.rowId === this.editRow.rowId) ? this.editRow : p),
        this.newRow,
    ];

    let allprojects = [...new Set(allrows.map(p => p.calculated.project).filter(p => p !== null).map(p => p!.projectId))].map(p => allrows.find(r => r.calculated.project?.projectId === p)!.calculated.project as Project);

    for (const project of allprojects) {

      let elements = await this.collectElementsForProject(project.projectId);

      let percSum = allrows
          .filter(p => p.calculated.project?.projectId === project.projectId)
          .map(p => p.cells[this.percentageColumnId??('' as ObjectId)]?.value ?? '100.0')
          .map(p => parseFloat(p))
          .filter(p => p)
          .reduce((a,b)=>a+b, 0);

      percSum = percSum / elements.length;

      let areaSum   = allrows
          .filter(p => p.calculated.project?.projectId === project.projectId)
          .map(p => p.calculated.area  )
          .filter(p => p !== null)
          .map(p => p!)
          .reduce((a,b)=>a+b, 0);

      let volumeSum = allrows
          .filter(p => p.calculated.project?.projectId === project.projectId)
          .map(p => p.calculated.volume)
          .filter(p => p !== null)
          .map(p => p!)
          .reduce((a,b)=>a+b, 0);

      let totalArea   = elements.map(p => p.area).reduce((a,b)=>a+b, 0);
      let totalVolume = elements.map(p => p.volume).reduce((a,b)=>a+b, 0);

      summaries.push({
        project:        project,

        percentageSum:  percSum,

        areaSum:        areaSum,
        volumeSum:      volumeSum,

        areaPerc:       (areaSum   / totalArea)   * 100,
        volumePerc:     (volumeSum / totalVolume) * 100,

        areaTotal:      totalArea,
        volumeTotal:    totalVolume,
      });
    }

    this.summaries = summaries;
  }

  async editOldRow(row: CalcRow) {
    if (!this.table) return;

    let aer = {...deepclone(row), rowId: row.rowId!};

    for (const col of this.table.columns) {
      if (!aer.cells[col.columnId]) aer.cells[col.columnId] = {value: ''};
    }

    this.editRow = aer;

    await this.updateSummaries();
  }

  async submitUpdatedOldRow() {
    if (this.table === null) return;
    if (this.editRow === null) return;

    for (const column of this.table.columns) {

      //const value = this.adminEditRow.cells[column.columnId].value;

      // ADMIN can do everything he pleases
      //
      //if (column.type === 'check' && column.mustBeChecked) {
      //  if (value !== 'true') {
      //    this.notification.error('Fehler', `Dert Wert "${column.header}" muss bestätigt werden`);
      //    return;
      //  }
      //}

      // ADMIN can do everything he pleases
      //
      //if (!column.allowEmpty) {
      //  if (value === '' || value === undefined) {
      //    this.notification.error('Fehler', `Dert Wert "${column.header}" muss ausgefüllt werden`);
      //    return;
      //  }
      //}

      this.editRow.cells[column.columnId].value = `${this.editRow.cells[column.columnId].value}`; // convert all to string (table-cols) (and set missing to empty-str)
    }

    for (const cid of Object.keys(this.editRow.cells).map(p=>p as ObjectId)) this.editRow.cells[cid].value = `${this.editRow.cells[cid].value}`; // convert all to string (obj-cols)

    try {

      let rawdata = await this.apiService.updateExistingRow(this.editRow);
      let data = {...rawdata, 'calculated': this.emptyCalculatedRowData()} as CalcRow;

      data = await this.updateCalculatedRowData(data);

      this.rows = this.rows.map(p => (p.rowId === data.rowId) ? data : p);

      this.editRow = null;

      await this.updateSummaries();

    } catch (err) {
      showAPIError(this.notification, 'Zeile konnten nicht aktualisiert werden', err);
    }
  }

  async cancelEditOldRow() {
    this.editRow = null;

    await this.updateSummaries();
  }

  async deleteOldRow() {
    if (this.table === null) return;
    if (this.editRow === null) return;

    try {

      const aer = this.editRow;

      await this.apiService.deleteExistingRow(aer.sheetId, aer.rowId);

      this.rows = this.rows.filter(p => (p.rowId !== aer.rowId));
      this.editRow = null;

      await this.updateSummaries();

    } catch (err) {
      showAPIError(this.notification, 'Zeile konnten nicht gelöscht werden', err);
    }
  }

  async openLatestSheet() {
    if (this.table === null) return;

    try {
      const sheets = (await this.apiService.getTableHistory(this.table.id)).sheets;
      await this.router.navigate(['/edit-sheet', sheets[0].sheetId]);
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
    }
  }

  async changeDate(v: Date) {
    if (this.table === null) return;

    try {
      const sheets = (await this.apiService.getTableHistory(this.table.id)).sheets;
      for (const s of sheets) {
        if (DateUtils.fparseRFC3339(s.created, 'yyyy-MM-dd') === DateUtils.format(v, 'yyyy-MM-dd')) {
          await this.router.navigate(['/edit-sheet', s.sheetId]);
          return
        }
      }
      showAPIError(this.notification, 'Tabelle konnte nicht gefunden werden', null);
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
    }
  }

  async downloadExcel() {
    if (this.table === null) return;
    if (this.sheet === null) return;

    try {

      let ced: CreateExcelData = {rows: []};

      ced.rows.push({cells: this.table.columns.map(p => p.header)});

      for (let i = 0; i < this.rows.length; i++) {
        const row = this.rows[i];
        ced.rows.push({cells: this.table.columns.map(col => (row.cells[col.columnId] === undefined && col.type !== 'rownumber') ? '' : this.getCellDisplayValue(col, row, i))});
      }

      ced.rows.push({cells: this.table.columns.map(_ => '')});

      for (const sum of this.summaries) {
        ced.rows.push({cells: this.table.columns.map(column => {

            if (column.type == 'project')       return sum.project.name;
            if (column.type == 'percentage')    return sum.percentageSum;
            if (column.type == 'elementArea')   return sum.areaSum.toFixed(2)   + ' / ' + sum.areaTotal.toFixed(2);
            if (column.type == 'elementVolume') return sum.volumeSum.toFixed(2) + ' / ' + sum.volumeTotal.toFixed(2);

            return '';

        })});
      }

      const res1 = await this.apiService.createExcel(ced);

      const cleanHeader = this.table.header.replaceAll(/[^A-Za-z0-9-.,_ÄÖÜäöüß=()\[\]]/g, '');

      window.open(environment.apiBaseUrl + 'excel/' + res1.hash + '/' + `table_${cleanHeader}_${DateUtils.fparseRFC3339(this.sheet.created, 'yyyy-MM-dd')}.xlsx`, '_blank');

    } catch (err) {
      showAPIError(this.notification, 'Excel konnten nicht erstellt werden', err);
    }
  }
}
