<epl-default-frame>
    <h1>Benutzerverwaltung</h1>

    <nz-table [nzData]="userData" [nzFrontPagination]="false">
        <thead>
        <tr>
            <th style="min-width:  64px;">ID</th>
            <th style="min-width: 128px;">Username</th>
            <th style="min-width: 128px;">Passwort</th>
            <th style="min-width: 150px;">Admin</th>
            <th style="min-width: 250px;">Tabelle</th>
            <th style="min-width: 128px;">Datum</th>
            <th style="min-width: 515px;"></th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td></td>
            <td><input nz-input nzSize="small" placeholder="Username"  [(ngModel)]="newUser_username"/></td>
            <td><input nz-input nzSize="small" placeholder="Passwort"  [(ngModel)]="newUser_password"/></td>
            <td><nz-switch [(ngModel)]="newUser_isAdmin"></nz-switch></td>
            <td>
                <nz-select *ngIf="!newUser_isAdmin" style="width: 100%;" [(ngModel)]="newUser_table" [nzAllowClear]="true">
                    <nz-option *ngFor="let t of tables" [nzLabel]="t.header" [nzValue]="t.id"></nz-option>
                </nz-select>
            </td>
            <td></td>
            <td class="actionbuttons">
                <button nz-button nzType="primary" style="flex-grow: 1"
                        [disabled]="newUser_username === '' || newUser_password === '' || (!newUser_isAdmin && newUser_table === null)"
                        (click)="addUser()"><span nz-icon nzType="plus"></span>Hinzufügen</button>
            </td>
        </tr>
        <tr *ngFor="let data of userData">
            <td>{{data.userId | shortid}}</td>
            <td>
                <ng-container *ngIf="currentEditingUser !== data.userId">{{data.username}}</ng-container>
                <ng-container *ngIf="currentEditingUser === data.userId"><input nz-input nzSize="small" placeholder="Username"  [(ngModel)]="currentEditingUser_username"/></ng-container>
            </td>
            <td>
                <ng-container *ngIf="currentEditingPass !== data.userId">•••</ng-container>
                <ng-container *ngIf="currentEditingPass === data.userId"><input nz-input nzSize="small" placeholder="Passwort"  [(ngModel)]="currentEditingPass_password"/></ng-container></td>
            <td>
                <ng-container *ngIf="currentEditingUser !== data.userId">{{data.isAdmin ? 'ADMINISTRATOR' : 'BENUTZER'}}</ng-container>
                <ng-container *ngIf="currentEditingUser === data.userId"><nz-switch [(ngModel)]="currentEditingUser_isAdmin"></nz-switch></ng-container>
            </td>
            <td class="table-list">
                <ng-container *ngIf="currentEditingUser !== data.userId"><div *ngFor="let t of data.visibleTables">{{convertTableName(t)}}</div></ng-container>
                <ng-container *ngIf="currentEditingUser === data.userId">
                    <nz-select *ngIf="!currentEditingUser_isAdmin" style="width: 100%;" [(ngModel)]="currentEditingUser_table" [nzAllowClear]="true">
                        <nz-option *ngFor="let t of tables" [nzLabel]="t.header" [nzValue]="t.id"></nz-option>
                    </nz-select>
                </ng-container>

            </td>
            <td><span style="white-space: pre">{{data.created | displaydate}}</span></td>
            <td class="actionbuttons">
                <button nz-button nzType="default" *ngIf="currentEditingUser === null && currentEditingPass === null"
                        (click)="startEditUser(data)"><span nz-icon nzType="edit"></span>Bearbeiten</button>
                <button nz-button nzType="primary" *ngIf="currentEditingUser === data.userId"
                        [disabled]="currentEditingUser_username === '' || (!currentEditingUser_isAdmin && currentEditingUser_table === null)"
                        (click)="editUser()"><span nz-icon nzType="edit"></span>Bearbeiten</button>

                <button nz-button nzType="default" *ngIf="currentEditingUser === null && currentEditingPass === null"
                        (click)="startEditPassword(data)"><span nz-icon nzType="unlock"></span>Passwort zurücksetzen</button>
                <button nz-button nzType="primary" *ngIf="currentEditingPass === data.userId"
                        [disabled]="currentEditingPass_password === ''"
                        (click)="editPassword()"><span nz-icon nzType="unlock"></span>Passwort zurücksetzen</button>

                <div class="grow"></div>

                <button nz-button nzType="default" *ngIf="currentEditingUser === data.userId || currentEditingPass === data.userId"
                        (click)="abortEdit()"><span nz-icon nzType="close"></span>Abbrechen</button>

                <button nz-button nzType="default" nzDanger *ngIf="currentEditingUser === null && currentEditingPass === null"
                        nz-popconfirm
                        nzPopconfirmTitle="Benutzer {{data.username}} wirklich löschen?"
                        (nzOnConfirm)="deleteUser(data)"><span nz-icon nzType="unlock"></span>Löschen</button>
            </td>
        </tr>

        </tbody>
    </nz-table>
</epl-default-frame>
