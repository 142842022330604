import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {AuthenticationService} from "../../services/authentication.service";
import {ApiService} from "../../services/api.service";
import {showAPIError} from "../../utils/api";
import {cellValueFromRow, getAreaFromRow, getVolumeFromRow, projectFromRow, Row, Sheet} from "../../interfaces/sheet";
import {Element} from "../../interfaces/project";
import {ColumnType, Table} from "../../interfaces/table";
import {Project} from "../../interfaces/project";
import {DateUtils} from "../../utils/date";
import {ObjectId} from "../../interfaces/utils";
import {environment} from "../../../environments/environment";
import {CreateExcelData} from "../../interfaces/excel";

interface ExtRow {
  row:         Row;
  table:       Table;
  project:     Project;
  elements:    Element[];
}

interface ExcelValue {
  value: string|number;
  str: string;
}

interface ExcelRow {
  cells: ExcelValue[];
}

interface ExcelData {
  header: string[];
  rows: ExcelRow[];
  summary: ExcelValue[]|null;
  filename: string;
}

@Component({
  selector: 'epl-week-overview',
  templateUrl: './week-overview.component.html',
  styleUrls: ['./week-overview.component.scss']
})
export class WeekOverviewComponent implements OnInit {

  loading: boolean = true;

  mode: 'week'|'month'|'year'|'total' = 'week';

  header: string = '';

  tableDataDays:       ExcelData|null = null;
  tableDataWeeks:      ExcelData|null = null;
  tableDataMonths:     ExcelData|null = null;
  tableDataYears:      ExcelData|null = null;

  tableDataProjects:   ExcelData|null = null;

  constructor(private activeRoute: ActivatedRoute,
              private notification: NzNotificationService,
              private authservice: AuthenticationService,
              private apiService: ApiService,
              private router: Router) {

  }

  async ngOnInit() {
    this.activeRoute.paramMap.subscribe(async params => {

      try {
        this.mode = this.activeRoute.snapshot.data['mode'] as ('week'|'month'|'year'|'total');

        if (this.mode === 'week') {
          const year = parseInt(params.get('year') ?? '');
          const kw   = parseInt(params.get('kw')   ?? '');

          const t0 = DateUtils.getDateOfISOWeek(kw, year);
          const t1 = DateUtils.addDays(t0, 6);

          this.header = `${year} - Kalenderwoche ${kw}`;

          await this.loadData(t0, t1, `${year}-KW${kw}`);
        }

        if (this.mode === 'month') {
          const year  = parseInt(params.get('year') ?? '');
          const month = parseInt(params.get('mon')  ?? '');

          const t0 = new Date(year, month,   1);
          const t1 = new Date(year, month+1, 0);

          this.header = `${year} - ${DateUtils.getMonthStr(t0)}`;

          await this.loadData(t0, t1, `${year}-${t0.getMonth()+1}`);
        }

        if (this.mode === 'year') {
          const year = parseInt(params.get('year') ?? '');

          const t0 = new Date(year,   0, 1);
          const t1 = new Date(year+1, 0, 0);

          this.header = `${year}`;

          await this.loadData(t0, t1, `${year}`);
        }

        if (this.mode === 'total') {

          const dr = await this.apiService.getDateRange();

          const t0 = DateUtils.parseRFC3339(dr.min ?? DateUtils.now())
          const t1 = DateUtils.parseRFC3339(dr.max ?? DateUtils.now())

          this.header = `Gesamtübersicht`;

          await this.loadData(t0, t1, 'total');
        }

      } catch (err) {
        showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
      }
    });
  }

  private async loadData(t0: Date, t1: Date, fnext: string) {

    this.loading = true;

    try {
      const projects = (await this.apiService.getProjects()).projects

      const tables = (await this.apiService.getTables()).tables;

      const sheets = (await this.apiService.listSheets(t0, t1)).sheets;

      const rows = (await this.apiService.listRows(t0, t1)).rows;

      const elements = (await this.apiService.getAllElements()).elements

      const tableMap    = new Map(tables.map(p => [p.id, p]));
      const projectsMap = new Map(projects.map(p => [p.projectId, p]));
      const elementsMap = new Map(projects.map(p => [p.projectId, elements.filter(e => e.projectId === p.projectId)]));

      // [0] ======== Add back deleted Projects ========
      {
        for (const row of rows) {
          const table = tableMap.get(row.tableId);
          if (!table) continue;
          const projectid = projectFromRow(table, row);
          if (!projectid) continue;
          if (!projectsMap.get(projectid)) {
            console.log('Force-Query deleted project', projectid);
            try {
              const fqp = await this.apiService.getProject(projectid);
              projects.push(fqp);
              projectsMap.set(projectid, fqp);
              const fqe = await this.apiService.getElementsByProject(projectid);
              for (const elem of fqe.elements) elements.push(elem);
              elementsMap.set(projectid, fqe.elements);
            } catch (err) {
              showAPIError(this.notification, `Projekt ${projectid} konnte nicht geladen werden (referenziert von row ${row.rowId})`, err);
            }
          }
        }
      }

      const extRows = rows
          .map(p => ({row: p, table: tableMap.get(p.tableId)}))
          .filter(p => p.table !== undefined)
          .map(p => ({row: p.row, table: p.table!, project: projectsMap.get(projectFromRow(p.table!, p.row) ?? ('' as ObjectId)) }))
          .filter(p => p.project !== undefined)
          .map(p => ({row: p.row, table: p.table!, project: p.project!, elements: elementsMap.get(p.project!.projectId) ?? [] } as ExtRow))

      // [1] ======== Projects ========
      {
        this.tableDataProjects = this.calculateProjectData(tables, rows, projects, elements, fnext);
      }

      // [2] ======== Days ========
      {
        let rdates = [t0];
        while (true) {
          const nd = new Date(rdates[rdates.length-1].getFullYear(), rdates[rdates.length-1].getMonth(), rdates[rdates.length-1].getDate()+1);
          if (nd.getTime() > t1.getTime()) break;
          rdates.push(nd);
        }

        this.tableDataDays = this.calculateOverviewData(
            tables,
            rdates,
            sheets,
            extRows,
            (a,b) => DateUtils.dateEquals(a, b),
                d => `${DateUtils.getWeekdayStr(d)}, ${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`,
            fnext,
        );

      }

      // [3] ======== Weeks ========
      {
        let rdates = [DateUtils.startOfWeek(t0)];
        while (true) {
          const nd = new Date(rdates[rdates.length-1].getFullYear(), rdates[rdates.length-1].getMonth(), rdates[rdates.length-1].getDate()+7);
          if (nd.getTime() > t1.getTime()) break;
          rdates.push(nd);
        }

        this.tableDataWeeks = this.calculateOverviewData(
            tables,
            rdates,
            sheets,
            extRows,
            (a,b) => DateUtils.getDateWeek(a) ==  DateUtils.getDateWeek(b),
                d => `KW ${DateUtils.getDateWeek(d)}`,
            fnext,
        );
      }

      // [4] ======== Months ========
      {
        let rdates = [DateUtils.getMonthOnly(t0)];
        while (true) {
          const nd = new Date(rdates[rdates.length-1].getFullYear(), rdates[rdates.length-1].getMonth()+1, 1);
          if (nd.getTime() > t1.getTime()) break;
          rdates.push(nd);
        }

        this.tableDataMonths = this.calculateOverviewData(
            tables,
            rdates,
            sheets,
            extRows,
            (a,b) => a.getMonth() === b.getMonth(),
                d => `${DateUtils.getMonthStr(d)} ${d.getFullYear()}`,
            fnext,
        );
      }

      // [5] ======== Years ========
      {
        let rdates = [new Date(t0.getFullYear(), 0, 1)];
        while (true) {
          const nd = new Date(rdates[rdates.length-1].getFullYear()+1, 0, 1);
          if (nd.getTime() > t1.getTime()) break;
          rdates.push(nd);
        }

        this.tableDataYears = this.calculateOverviewData(
            tables,
            rdates,
            sheets,
            extRows,
            (a,b) => a.getFullYear() === b.getFullYear(),
            d => `${d.getFullYear()}`,
            fnext,
        );
      }

    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
    } finally {
      this.loading = false;
    }
  }

  private calculateProjectData(tables: Table[], rows: Row[], projects: Project[], elements: Element[], fnext: string) {
    let dataProjects: ExcelData = {
      summary: null,
      rows: [],
      header: ['Tabelle', 'Projekt', 'Fertig (m²)', 'Gesamt (m²)', 'Fertig (m³)', 'Gesamt (m³)', 'Einträge'],
      filename: `projects_${fnext}.xlsx`,
    }

    for (const table of tables) {
      const tablerows = rows.filter(p => p.tableId === table.id);

      const projectids = [...new Set(tablerows.map(p => cellValueFromRow(table, p, ColumnType.Project)).filter(p => p !== null).map(p => p!))];

      for (const projectid of projectids) {
        const project = projects.find(p => p.projectId === projectid);
        if (!project) continue;

        const projectrows = tablerows.filter(p => cellValueFromRow(table, p, ColumnType.Project) === project.projectId);

        let percSum = projectrows
            .map(p => parseFloat(cellValueFromRow(table, p, ColumnType.Percentage) ?? ''))
            .filter(p => p)
            .reduce((a, b) => a + b, 0);

        let areaSum = projectrows
            .map(p => getAreaFromRow(table, p, project, elements))
            .filter(p => p)
            .map(p => p!)
            .reduce((a, b) => a + b, 0);

        let volumeSum = projectrows
            .map(p => getVolumeFromRow(table, p, project, elements))
            .filter(p => p)
            .map(p => p!)
            .reduce((a, b) => a + b, 0);

        dataProjects.rows.push({
          cells: [
            {value: table.header,       str: table.header},
            {value: project.name,       str: project.name},
            {value: areaSum,            str: `${areaSum?.toFixed(2) ?? '-'} m²`},
            {value: project.areaSum,    str: `${project.areaSum?.toFixed(2) ?? '-'} m²`},
            {value: volumeSum,          str: `${volumeSum?.toFixed(2) ?? '-'} m³`},
            {value: project.volumeSum,  str: `${project.volumeSum?.toFixed(2) ?? '-'} m³`},
            {value: projectrows.length, str: `${projectrows.length}`},
          ]
        });
      }
    }

    dataProjects.summary = null;
    return dataProjects;
  }

  private calculateOverviewData(tablesArr: Table[], days: Date[], sheets: Sheet[], extRows: ExtRow[], comp: ((d1:Date, d2:Date)=>boolean), fmt: (d:Date)=>string, fnext: string): ExcelData {
    let data: ExcelData = {
      summary: null,
      rows: [],
      header: ['', 'm²', 'm³', 'Personen', 'Leistungsstunden Büro', 'Leistungsstunden Betrieb', 'Leistungsstunden Dübelproduktion', 'Leistungsstunden Sonstiges', 'Einträge'],
      filename: `projects_${this.mode}_${fnext}.xlsx`
    }

    const tables = new Map(tablesArr.map(p => [p.id, p]));

    let sumPersonCount      = 0;
    let sumHourCountOffice  = 0;
    let sumHourCountFactory = 0;
    let sumHourCountDowel   = 0;
    let sumHourCountOther   = 0;
    let sumAreaSum          = 0;
    let sumVolumeSum        = 0;
    let sumRowCount         = 0;

    for (const date of days) {

      const daySheetsAll          = sheets.filter(p => comp(DateUtils.parseRFC3339(p.created), date));
      const daySheetsEnabledSums  = sheets.filter(p => comp(DateUtils.parseRFC3339(p.created), date)).filter(p => tables.get(p.tableId)?.enableElementSums ?? true);
      const daySheetsEnabledHours = sheets.filter(p => comp(DateUtils.parseRFC3339(p.created), date)).filter(p => tables.get(p.tableId)?.enableHours       ?? true);

      const daySheetIdsAll          = new Set(daySheetsAll         .map(p => p.sheetId));
      const daySheetIdsEnabledSums  = new Set(daySheetsEnabledSums .map(p => p.sheetId));
      const daySheetIdsEnabledHours = new Set(daySheetsEnabledHours.map(p => p.sheetId));

      const dayRowsAll          = extRows.filter(p => daySheetIdsAll.has(p.row.sheetId));
      const dayRowsEnabledSums  = extRows.filter(p => daySheetIdsEnabledSums.has(p.row.sheetId));
      const dayRowsEnabledHours = extRows.filter(p => daySheetIdsEnabledHours.has(p.row.sheetId));

      const txt = fmt(date);

      const personCount      = daySheetsEnabledHours.map(p => p.numberWorkers).reduce((a, b) => a + b, 0);
      const hourCountOffice  = daySheetsEnabledHours.map(p => p.hoursOffice  ).reduce((a, b) => a + b, 0);
      const hourCountFactory = daySheetsEnabledHours.map(p => p.hoursFactory ).reduce((a, b) => a + b, 0);
      const hourCountDowel   = daySheetsEnabledHours.map(p => p.hoursDowel   ).reduce((a, b) => a + b, 0);
      const hourCountOther   = daySheetsEnabledHours.map(p => p.hoursOther   ).reduce((a, b) => a + b, 0);

      let areaSum = dayRowsEnabledSums
          .map(p => getAreaFromRow(p.table, p.row, p.project, p.elements))
          .filter(p => p)
          .map(p => p!)
          .reduce((a, b) => a + b, 0);

      let volumeSum = dayRowsEnabledSums
          .map(p => getVolumeFromRow(p.table, p.row, p.project, p.elements))
          .filter(p => p)
          .map(p => p!)
          .reduce((a, b) => a + b, 0);

      let rowCount = dayRowsAll.length;

      sumPersonCount      += personCount;
      sumHourCountOffice  += hourCountOffice;
      sumHourCountFactory += hourCountFactory;
      sumHourCountDowel   += hourCountDowel;
      sumHourCountOther   += hourCountOther;
      sumAreaSum          += areaSum;
      sumVolumeSum        += volumeSum;
      sumRowCount         += rowCount;

      data.rows.push({
        cells: [
          {value: txt,              str: txt},
          {value: areaSum,          str: `${areaSum.toFixed(2)} m²`},
          {value: volumeSum,        str: `${volumeSum.toFixed(2)} m³`},
          {value: personCount,      str: `${personCount}`},
          {value: hourCountOffice,  str: `${hourCountOffice}`},
          {value: hourCountFactory, str: `${hourCountFactory}`},
          {value: hourCountDowel,   str: `${hourCountDowel}`},
          {value: hourCountOther,   str: `${hourCountOther}`},
          {value: rowCount,         str: `${rowCount}`},
        ]
      });
    }

    data.summary = [
      {value : '\u2211',            str: '@sum'},
      {value : sumAreaSum,          str: `${sumAreaSum.toFixed(2)} m²`},
      {value : sumVolumeSum,        str: `${sumVolumeSum.toFixed(2)} m³`},
      {value : sumPersonCount,      str: `${sumPersonCount}`},
      {value : sumHourCountOffice,  str: `${sumHourCountOffice}`},
      {value : sumHourCountFactory, str: `${sumHourCountFactory}`},
      {value : sumHourCountDowel,   str: `${sumHourCountDowel}`},
      {value : sumHourCountOther,   str: `${sumHourCountOther}`},
      {value : sumRowCount,         str: `${sumRowCount}`},
    ];

    return data;
  }

  async download(ed: ExcelData) {

    try {

      let ced: CreateExcelData = {rows: []};

      ced.rows.push({cells: ed.header})
      for (const row of ed.rows) {
        ced.rows.push({cells: row.cells.map(p => p.value)})
      }

      if (ed.summary && ed.rows.length > 0) ced.rows.push({cells: ed.summary.map(p => p.value)});

      const res1 = await this.apiService.createExcel(ced);

      window.open(environment.apiBaseUrl + 'excel/' + res1.hash + '/' + ed.filename, '_blank');

    } catch (err) {
      showAPIError(this.notification, 'Blatt konnten nicht aktualisiert werden', err);
    }
  }
}