import {ObjectId} from "./utils";
import {RFC3339} from "./datetime";
import {Element, Project} from "./project";
import {ColumnType, Table} from "./table";

export interface Sheet {
  sheetId: ObjectId;
  tableId: ObjectId;
  rowCount: number;
  created: RFC3339;
  deleted: RFC3339;
  numberWorkers: number;
  hoursOffice: number;
  hoursFactory: number;
  hoursDowel: number;
  hoursOther: number;
}

export interface Row {
  rowId?: ObjectId;
  cells: {[_:ObjectId]: Cell};
  created?: RFC3339;
  sheetId: ObjectId;
  tableId: ObjectId;
}

export interface CreateRow {
  cells: {[_:ObjectId]: CreateCell};
  sheetId: ObjectId;

  calculated:CalculatedRowData;
}

export interface UpdateRow {
  rowId: ObjectId;

  cells: {[_:ObjectId]: CreateCell};
  sheetId: ObjectId;

  calculated:CalculatedRowData;
}

export interface Cell {
  value: string;
}

export interface CreateCell {
  value: string;
}

export interface CalcRow extends Row {
  rowId?: ObjectId;
  cells: { [_: ObjectId]: Cell };
  created?: RFC3339;
  sheetId: ObjectId;
  tableId: ObjectId;

  calculated:CalculatedRowData;
}

export interface CalculatedRowData {
  project:         Project|null;
  element:         Element|null;
  projectElements: Element[];
  volume:          number|null;
  area:            number|null;
}

export function cellValueFromRow(table: Table, row: Row, ct: ColumnType): string|null {
  for (const column of table.columns) {
    if (column.type === ct) {
      if (row.cells[column.columnId]) {
        return row.cells[column.columnId].value;
      }
    }
  }

  return null;
}

export function projectFromRow(table: Table, row: Row): ObjectId|null {
  return cellValueFromRow(table, row, ColumnType.Project) as (ObjectId|null);
}

export function getVolumeFromRow(table: Table, row: Row, proj: Project, elements: Element[], forceCalc: boolean = false): number|null {
  const precalc = cellValueFromRow(table, row, ColumnType.ElementVolume);
  if (!forceCalc && precalc && parseFloat(precalc)) return parseFloat(precalc);

  const perc = parseFloat(cellValueFromRow(table, row, ColumnType.Percentage) ?? '100.0');
  if (!perc) return null

  const projid = cellValueFromRow(table, row, ColumnType.Project);
  if (!projid) return null

  if (projid !== proj.projectId) return null;

  const elemid = cellValueFromRow(table, row, ColumnType.Element);
  if (!elemid) return null

  const elem = elements.find(p => p.projectId === projid && p.elementId === elemid)
  if (! elem) return null;

  return elem.volume * (perc/100);
}

export function getAreaFromRow(table: Table, row: Row, proj: Project, elements: Element[], forceCalc: boolean = false): number|null {
  const precalc = cellValueFromRow(table, row, ColumnType.ElementArea);
  if (!forceCalc && precalc && parseFloat(precalc)) return parseFloat(precalc);

  const perc = parseFloat(cellValueFromRow(table, row, ColumnType.Percentage) ?? '100.0');
  if (!perc) return null

  const projid = cellValueFromRow(table, row, ColumnType.Project);
  if (!projid) return null

  if (projid !== proj.projectId) return null;

  const elemid = cellValueFromRow(table, row, ColumnType.Element);
  if (!elemid) return null

  const elem = elements.find(p => p.projectId === projid && p.elementId === elemid)
  if (! elem) return null;

  return elem.area * (perc/100);
}

export function getRowIsProject(row: Row, tab: Table, proj: Project): boolean {
  const col = tab.columns.find(p => p.type === ColumnType.Project);
  if (!col) return false;

  const cv = row.cells[col.columnId];
  if (!cv) return false;

  return (cv.value === proj.projectId);
}
