import {ObjectId} from "./utils";
import {RFC3339} from "./datetime";

export enum ColumnType {
  Text          = "text",
  Number        = "number",
  Check         = "check",
  Date          = "date",
  Select        = "select",
  Percentage    = "percentage",

  Project       = "project",
  Element       = "element",
  ElementArea   = "elementArea",
  ElementVolume = "elementVolume",
  ElementIdent  = "elementIdent",

  Employee      = "employee",

  RowNumber     = "rownumber",

}

export const ColumnTypes: ColumnType[] = [
  ColumnType.Text,
  ColumnType.Number,
  ColumnType.Check,
  ColumnType.Date,
  ColumnType.Select,
  ColumnType.Percentage,

  ColumnType.Project,
  ColumnType.Element,
  ColumnType.ElementArea,
  ColumnType.ElementVolume,
  ColumnType.ElementIdent,

  ColumnType.Employee,

  ColumnType.RowNumber,
];

export interface Table {
  id: ObjectId;
  header: string;
  deleted: RFC3339|null;
  columns: ColumnDefinition[];
  enableHours: boolean;
  enableElementSums: boolean;
  enableSummaryRows: boolean;
}

export interface ColumnDefinition {
  columnId: ObjectId;
  type: ColumnType;
  header: string;
  created: Date|null;
  selectValues: string[];
  autoFillFromPrevious: boolean;
  width: string;
  allowEmpty: boolean;
  mustBeChecked: boolean;
}
