
<epl-default-frame>
  <div class="overview_list">

    <ng-container *ngFor="let entry of data">

      <a class="overview" [class.hasproject]="entry.activeProject !== null" [routerLink]="['/edit-sheet/', entry.sheet.sheetId]">

        <span class="title">{{entry.table.header}}</span>

        <span class="rowcount"><i class="bg"></i><span class="val">{{entry.rows.length}}</span></span>

        <span *ngIf="entry.activeProject !== null" class="project">{{entry.activeProject.name}}</span>

        <span *ngIf="entry.percArea   !== null" class="percArea">{{entry.percArea | numberFixed:2}} %</span>

        <span *ngIf="entry.percVolume !== null" class="percVolume">{{entry.percVolume | numberFixed:2}} %</span>

      </a>

    </ng-container>

  </div>
</epl-default-frame>
