import { Component } from '@angular/core';
import {FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import * as faSolid from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isCollapsed = false;

  constructor(library: FaIconLibrary, faConfig: FaConfig) {

    library.addIcons(
      faSolid.faSave,
      faSolid.faSpinner,
      faSolid.faRightToBracket,
      faSolid.faRightFromBracket,
      faSolid.faLoader,
      faSolid.faUserRobotXmarks,
      faSolid.faX,
      faSolid.faPlus,
      faSolid.faTrash,
      faSolid.faUpload,
      faSolid.faImage,
      faSolid.faArrowLeft,
      faSolid.faFilePen,
      faSolid.faSquareList,
      faSolid.faHome,
      faSolid.faFolder,
      faSolid.faSquarePollVertical,
      faSolid.faCaretUp,
      faSolid.faCaretDown,
      faSolid.faMagnifyingGlass,
      faSolid.faCircleXmark,
      faSolid.faHammer,
      faSolid.faGauge,
      faSolid.faUserGear,
      faSolid.faBuilding,
      faSolid.faUserTie,
      faSolid.faUser,
      faSolid.faNoteSticky,
      faSolid.faFrame,
      faSolid.faCalendarStar,
      faSolid.faRobot,
      faSolid.faGear,
      faSolid.faRectangleHistory,
      faSolid.faUsers,
      faSolid.faCalendar,
    )

    faConfig.defaultPrefix = 'fas';
    faConfig.fixedWidth = true;
  }
}
