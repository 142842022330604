import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'iszerocell', pure: true})
export class IsZeroCellPipe implements PipeTransform {

  transform(value: string): boolean {
    return ['0', '0.0', '0.00', '0 m²', '0 m³', '0.00 m²', '0.00 m³'].includes(value);
  }

}
