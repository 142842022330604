import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'numberFixed', pure: true })
export class NumberFixedPipe implements PipeTransform {

  transform(value: number|null, args: number): string {
    return value?.toFixed(args) ?? '-';
  }

}
