<epl-default-frame>

  <div class="fullpageloader" *ngIf="loading">
    <nz-spin></nz-spin>
  </div>

  <ng-container *ngIf="!loading">

    <h1 *ngIf="tableId === null" >Tabelle erstellen</h1>
    <h1 *ngIf="tableId !== null" >Tabelle bearbeiten</h1>

    <div style="height: 2rem"></div>

    <div class="data data_name">
      <h4>Stationsname:</h4>
      <input nz-input placeholder="Tabellenname" class="col-text bottom-distance" [(ngModel)]="tableName"/>
    </div>


    <div class="data data_columns">
      <h4>Spalten:</h4>
      <nz-table [nzData]="columns" [nzFrontPagination]="false">

        <thead>
        <tr>
          <th>Überschrift</th>
          <th>Typ</th>
          <th>Optionen</th>
          <th>Extra</th>
          <th></th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let data of columns; let i = index">
          <td>
            <input nz-input placeholder="Tabellenname" [(ngModel)]="data.header"/>
          </td>
          <td>
            <nz-select style="width: 100%" [(ngModel)]="data.type" >
              <nz-option *ngFor="let option of options" [nzValue]="option" [nzLabel]="option | columnTypeToStr"></nz-option>
            </nz-select>
          </td>

          <td>
            <div style="display: flex; flex-direction: column;">
              <nz-checkbox-wrapper>
                <label nz-checkbox [(ngModel)]="data.allowEmpty">Leere Werte erlauben</label>
              </nz-checkbox-wrapper>
              <nz-checkbox-wrapper>
                <label nz-checkbox [(ngModel)]="data.autoFillFromPrevious">Werte aus voreriger Zeile übernehmen</label>
              </nz-checkbox-wrapper>
              <nz-checkbox-wrapper *ngIf="data.type === 'check'">
                <label nz-checkbox [(ngModel)]="data.mustBeChecked">Muss bestätigt werden</label>
              </nz-checkbox-wrapper>
              <div style="display: grid; grid-template-columns: auto 1fr; grid-column-gap: 0.5rem; padding-left: 24px">
                <label style="display: flex; align-items: center;">Manuelle Breite:</label>
                <input nz-input placeholder="Breite (px|em|%)" [(ngModel)]="data.width" style="max-width: 10rem;"/>
              </div>
            </div>
          </td>

          <td *ngIf="data.type === 'select'">
            <div class="select-value-cell">
              <input nz-input placeholder="Auswahlmöglichkeit" #selectValueInput/>
              <button nz-button nzType="default" (click)="addSelectValue(data, selectValueInput)"><span nz-icon nzType="plus"></span></button>
              <div class="value-list" *ngIf="data.selectValues.length > 0">
                <span *ngFor="let v of data.selectValues">{{v}} <button nz-button nzDanger nzType="default" (click)="deleteSelectValue(data, v)"><span nz-icon nzType="delete"></span></button></span>
              </div>
            </div>
          </td>

    <!--      <td *ngIf="data.type === 'project'">-->
    <!--        <div class="select-value-cell">-->
    <!--          <nz-select placeholder="Projektname" #projectNameInput>-->
    <!--            <nz-option *ngFor="let p of projects" [nzValue]="p" [nzLabel]="p"></nz-option>-->
    <!--          </nz-select>-->
    <!--          <button nz-button nzType="default" (click)="addProject(data)"><span nz-icon nzType="plus"></span></button>-->
    <!--          <div class="value-list" *ngIf="data.selectValues.length > 0">-->
    <!--            <span *ngFor="let v of data.selectValues">{{v}} <button nz-button nzDanger nzType="default" (click)="deleteSelectValue(data, v)"><span nz-icon nzType="delete"></span></button></span>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </td>-->

          <td *ngIf="data.type !== 'select'">-</td>

          <td>
            <button nz-button nzType="default"          [disabled]="i == 0"                style="margin-left: 0.5rem" (click)="moveColumnUp(i, data)"><span nz-icon nzType="caret-up"></span></button>
            <button nz-button nzType="default"          [disabled]="i == columns.length-1" style="margin-left: 0.5rem" (click)="moveColumnDown(i, data)"><span nz-icon nzType="caret-down"></span></button>
            <button nz-button nzType="default" nzDanger [disabled]="columns.length === 1"  style="margin-left: 0.5rem" (click)="deleteColumn(i, data)"><span nz-icon nzType="delete"></span></button>
            <button nz-button nzType="default"          [disabled]="false"                 style="margin-left: 0.5rem" (click)="addColumnAbove(i, data)"><span nz-icon nzType="insert-row-above"></span></button>
            <button nz-button nzType="default"          [disabled]="false"                 style="margin-left: 0.5rem" (click)="addColumnBelow(i, data)"><span nz-icon nzType="insert-row-below"></span></button>
          </td>
        </tr>
        </tbody>

      </nz-table>
    </div>

    <div class="data data_props">
      <h4>Eigenschaften:</h4>

      <div class="props">
        <label>Eingabe Leistungsstunden aktivieren</label>
        <nz-switch [(ngModel)]="enableHours"></nz-switch>
        <label>Summen (m² und m³) in Übersichten berücksichtigen</label>
        <nz-switch [(ngModel)]="enableElementSums"></nz-switch>
        <label>Zusammenfassungszeile(n) aktivieren</label>
        <nz-switch [(ngModel)]="enableSummaryRows"></nz-switch>
      </div>
    </div>

    <div style="display: flex; gap: 0.5rem">
      <button nz-button nzType="primary"          (click)="submitTable()">Speichern</button>
      <button nz-button nzType="primary" nzDanger routerLink="/tables">Abbrechen</button>
    </div>

  </ng-container>

</epl-default-frame>
