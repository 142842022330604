import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NzNotificationService} from "ng-zorro-antd/notification";
import {formatAPIError, isErrorCode, showAPIError} from "../../utils/api";
import {Router} from "@angular/router";
import {PlatformLocation} from "@angular/common";
import {Title} from "@angular/platform-browser";
import {SidebarService} from "../../services/sidebar.service";
import {NzSiderComponent} from "ng-zorro-antd/layout";
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {skip} from "rxjs";
import {AuthenticationService} from "../../services/authentication.service";
import {User} from "../../interfaces/user";
import {ApiService} from "../../services/api.service";

@Component({
  selector: 'epl-default-frame',
  templateUrl: './default-frame.component.html',
  styleUrls: ['./default-frame.component.scss']
})
export class DefaultFrameComponent implements OnInit {
  user: User|null = null;

  constructor(private breakpointObserver: BreakpointObserver,
              private sidebar: SidebarService,
              private notification: NzNotificationService,
              private apiservice: ApiService,
              private router: Router,
              private authservice: AuthenticationService,
              private platformLocation: PlatformLocation) {

  }

  async ngOnInit() {

    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .pipe(skip(1))
      .subscribe((state: BreakpointState) => { console.log('breakpoint: ' + state.matches); this.setCollapsed(state.matches); });

    if (this.authservice.isAuthenticated()) {
      this.user = this.authservice.getSelf();
      if (this.user === null) {
        try {
          const data = await this.apiservice.getUser(this.authservice.getSelfID()!);
          this.authservice.setSelf(data);
          this.user = data;
        } catch (err) {
          showAPIError(this.notification, 'Benutzer konnte nicht geladen werden', err)
        }
      }
    } else {
      this.user = null;
    }
  }

  isRoot() {
    return (this.platformLocation.pathname === '/') || (this.platformLocation.pathname === '/admin');
  }

  isCollapsed() {
    return this.sidebar.isCollapsed;
  }

  setCollapsed(v: boolean) {
    this.sidebar.isCollapsed = v;
  }

  async logout() {
    this.authservice.clearAuth();
    await this.router.navigate(['/login'], {queryParams: {}});
  }
}
