import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberArray'
})
export class NumberArrayPipe implements PipeTransform {

  transform(n: number): number[] {
    return [...Array(n)].map((_,i) => i);  }

}
